import React from "react";
import { apiMainURL } from '../../config/environment';
import './scss/cards.scss'
import List from "./list";
import Help from "./help";

export const Cards = () => {
  const [menu, setMenu] = React.useState<any>([]);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuLOpen, setMenuLOpen] = React.useState(false);
  const [filter, setFilter]= React.useState("");
  const [language, setLanguage]= React.useState("spa");
  const [clear, setClear]= React.useState(false);

  const [help, setHelp]= React.useState(false);

  const [icoMenuLanguage, setIcoMenuLanguage] = React.useState('');
  const [cards, setCards] = React.useState<any>([]);
  const [onBoard, setOnBoard] = React.useState([]);


  const [icons, setIcons] = React.useState<any>({});
  const [images, setImages] = React.useState<any>({});
  const [config, setConfig] = React.useState<any>({});

  const [bgFooterHelp, setBGFooterHelp] = React.useState(null);
  const [bgFooterRestart, setBGFooterRestart] = React.useState(null);
  const [hoveredRestart, setHoveredRestart] = React.useState(false);
  const [hoveredHelp, setHoveredHelp] = React.useState(false);
  React.useEffect(()=>{
    if(clear){
      setClear(false)
    }
  },[clear])
  React.useEffect(()=>{
    setMenuOpen(false)
  },[filter])
  React.useEffect(()=>{
    if(menuOpen){
      setMenuLOpen(false)
    }
  },[menuOpen])
  React.useEffect(()=>{
    if(menuLOpen){
      setMenuOpen(false)
    }
  },[menuLOpen])
  React.useEffect(()=>{
    if(help){
      setMenuOpen(false)
      setMenuLOpen(false)
    }
  },[help])
  React.useEffect(()=>{
    if(images){
      setBGFooterHelp(images.buttonBlack)
      setBGFooterRestart(images.buttonYellow);
    }
  },[images])
  React.useEffect(()=>{
    if(icons){
      setIcoMenuLanguage(icons.esp)
    }
  },[icons])
  React.useEffect(()=>{
    if(hoveredRestart){
      setBGFooterRestart(images.buttonYellowHover);
    }else{
      setBGFooterRestart(images.buttonYellow);
    }
  },[hoveredRestart]);
  React.useEffect(()=>{
    if(hoveredHelp){
      setBGFooterHelp(images.buttonBlackHover);
    }else{
      setBGFooterHelp(images.buttonBlack);
    }
  },[hoveredHelp]);
  React.useEffect(()=>{
    const myHeaders = new Headers();
    myHeaders.append(
        'authorization',
        `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };


    fetch(`${apiMainURL}/api/v1/culture-cards?_format=json`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const data = JSON.parse(result);
          setCards(data.cultureCards)
          setOnBoard(data.onBoard)
          setIcons(data.appConfig.icons)
          setImages({
                ...data.appConfig.images,
                cartasBlack:data.appConfig.imagesCartasBlack,
                cartasWhite:data.appConfig.imagesCartasWhite,
                duenosBlack:data.appConfig.imagesDuenosBlack,
                duenosWhite:data.appConfig.imagesDuenosWhite,
                scroll:data.appConfig.imagesScroll
          })

          const cardsNames = data.onBoard.filter((item:any) => {
            const title = item?.title.spa?.toLowerCase();
            return title?.includes("cartas");
          });
          const categoriesUnique = data.cultureCards
              .filter((item: { status: string; }) => item.status === "true")
              .filter((item: { category: any; }, index: any, self: any[]) => index === self.findIndex(obj =>
                  JSON.stringify(obj.category) === JSON.stringify(item.category)
              ));
          const menuOptions = [];
          for (const categoryItem of categoriesUnique) {
            for (const titleItem of cardsNames) {
              const palabrasCategory = Object.values(categoryItem.category.text).map((value:any) => value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
              const palabrasTitle = Object.values(titleItem.title).map((value:any) => value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
              for (const palabra of palabrasCategory) {
                if (palabrasTitle.some(titlePalabra => titlePalabra.includes(palabra))) {
                  menuOptions.push({ id: categoryItem.category.id, title: titleItem.title });
                  break;
                }
              }
            }
          }
          setMenu(menuOptions)
          const translate={
            helpMenu:{...data.appConfig?.onboardtitle},
            helpBack:{...data.appConfig?.onboardback},
            viewAll:{...data.appConfig?.viewAll},
            menuClose:{...data.appConfig?.menuClose},
            footerHelp:{...data.appConfig?.footerHelp},
            footerRestart:{...data.appConfig?.footerRestart},
            changeLanguage:{...data.appConfig?.changeLanguage}
          }
          setConfig({...translate})

        })
        .catch((error) => console.error(error));
  },[])



  return (
      <div className="main-content">
        <div className="container-fluid position-relative">
          <div className="row justify-content-center " style={{backgroundImage:`url(${images.bodyBGDesktop})`}}>

            <div className={`${menuLOpen ? "visible" :" hidden"}`}>
              <div style={{backgroundImage:`url(${images.menuLanguagesBG})`}} className={`position-absolute open-l-menu row m-0 align-content-center `} >
                <div className="col-12 d-flex align-items-center open-l-menu-title" >
                  {config?.changeLanguage && config?.changeLanguage[language] }
                </div>
                <div className={`col-10 d-flex align-items-center open-l-menu-button ${language==="eng" && "active"}`}  onClick={()=>{setLanguage("eng");setMenuLOpen(false);setIcoMenuLanguage(icons.eng)}}>
                  <img src={icons.eng} alt=""/> English - EN
                </div>
                <div className={`col-10 d-flex align-items-center open-l-menu-button ${language==="spa" && "active"}`} onClick={()=>{setLanguage("spa");setMenuLOpen(false);setIcoMenuLanguage(icons.esp)}}>
                  <img src={icons.esp} alt=""/> Español - ES
                </div>
              </div>
            </div>
            <div className={`col-12 mt-sm-2 mt-md-3 p-0 cards-header ${help && "d-none d-md-block"}  `} style={{backgroundImage:`url(${images.headerBGDesktop})`}}>
              <div className="d-flex position-relative h-100">
                {images.cartasWhite && <img className="center menu-l-logo" src={images.cartasWhite[language]} alt=""/>}
                <button className="menu-l-button" onClick={() => {
                  setMenuLOpen(!menuLOpen)
                }}>
                <img className="menu-l-icon" src={icoMenuLanguage} alt=""/>
                </button>
                <button className="menu-button"  style={{backgroundImage:`url(${images.menuBG})`}} onClick={()=>{setMenuOpen(!menuOpen)}}>
                  MENU <img className="menu-icon" src={icons.menu} alt=""/>
                </button>
                <div className={`${menuOpen ? "visible " :" hidden"}`}>
                  <div className={`open-menu row m-0 p-3 align-content-between`} >
                    <div className="col-12 p-0">
                      <div className="row m-0 align-content-start">
                        <div className="col-6 mt-4 d-flex align-items-center justify-content-start"><img width={149} height={48} src={images.cartasBlack && images.cartasBlack[language]} alt=""/></div>
                        <div className="col-6 mt-4 d-flex align-items-center justify-content-end">
                          <button className="menu-button-close" style={{backgroundImage:`url(${images.menuCloseBG})`}} onClick={()=>{setMenuOpen(false)}}>
                            {config?.menuClose && config?.menuClose[language] } <img className="menu-icon" src={icons.menuClose} alt=""/>
                          </button>
                        </div>
                        {config?.viewAll &&
                            <div className="col-12 mt-4 p-0">
                              <button style={{backgroundImage:`url(${images.menuItemBG})`}}  className="menu-item position-relative" onClick={()=>{setHelp(false);setMenuOpen(false);setFilter("")}}>
                                {config?.viewAll[language]} <img className="menu-item-icon" src={icons.menuItem} alt=""/>
                              </button>
                            </div>
                        }
                        {menu.map((item:any) => (
                            <div key={item.id} className="col-12 mt-4 p-0">
                              <button
                                  style={{ backgroundImage: `url(${images.menuItemBG})` }}
                                  className="menu-item position-relative"
                                  onClick={() => { setHelp(false); setFilter(item.id) }}
                              >
                                {item?.title[language]} <img className="menu-item-icon" src={icons.menuItem} alt="" />
                              </button>
                            </div>
                        ))}
                        {config?.helpMenu &&
                            <div className="col-12 mt-4 p-0">
                              <button style={{backgroundImage:`url(${images.menuItemBG})`}} onClick={()=>setHelp(true)} className="menu-item position-relative">
                                {config?.helpMenu[language]} <img className="menu-item-icon" src={icons.menuItem} alt=""/>
                              </button>
                            </div>
                        }
                      </div>
                    </div>
                    <div className="col-12 p-0">
                      <div className="row m-0">
                        <div className="col-6 d-flex align-items-end justify-content-start"><img height={34}
                                                                                                 src={images.logoAbi}
                                                                                                 alt=""/></div>
                        <div className="col-6 d-flex align-items-center justify-content-end"><img height={104}
                                                                                                  src={images.duenosBlack && images.duenosBlack[language]}
                                                                                                  alt=""/></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-md-12 ${help ? "" : "pt-5 pb-5"}`}>
              <div className="row m-0 justify-content-center">
                <div className="col-md-10 col-sm-12">
                  <div className={`${menuOpen ? "visible menu-opened" : " hidden"}`}></div>
                  {
                    help ?
                        <Help data={onBoard} config={config} images={images} icons={icons} language={language} back={() => {
                          setHelp(false)
                        }}/> :
                        cards.length > 0 &&
                        <List items={cards} images={images} icons={icons} searcher={filter} language={language}
                              clear={clear}/>
                  }
                </div>
              </div>
            </div>
              {images.scroll && <img src={images.scroll[language]} className="d-none d-md-block img-scroll" alt=""/>}
              {!help &&
                  <div className="cards-footer d-flex justify-content-center" style={{backgroundImage:`url(${images.footerBGDesktop})`}}>
                    <button className="menu-footer-button help-button position-relative"  style={{backgroundImage:`url(${bgFooterHelp})`}}
                            onMouseEnter={() => setHoveredHelp(true)}
                            onMouseLeave={() => setHoveredHelp(false)}
                            onClick={()=>{setHelp(true)}}>
                      <div className="position-absolute">
                        {config?.footerHelp && config?.footerHelp[language] }  <img className="menu-icon" src={icons.helpFooter} alt=""/>
                      </div>
                    </button>
                    <button className="menu-footer-button restart-button position-relative"  style={{backgroundImage:`url(${bgFooterRestart})`}}
                            onMouseEnter={() => setHoveredRestart(true)}
                            onMouseLeave={() => setHoveredRestart(false)}
                            onClick={()=>{setClear(true)}}>
                      <div className="position-absolute">
                        {config?.footerRestart && config?.footerRestart[language] }  <img className="menu-icon" src={icons.restart} alt=""/>
                      </div>
                    </button>
                  </div>
              }
          </div>
        </div>
      </div>
  );
};
