import { useState, useEffect } from 'react';

import { useGetDefaultContentQuery } from '../../../../api/userSession';
import { getContestVideo, getListContestVideos } from '../../../../api/contest';

import { getDefaultContent } from '../../../../modules/services/getElements.service';

export function useVideoContest() {
  const defaultData = useGetDefaultContentQuery('embajador-mision')?.data;
  const elem: any = getDefaultContent(defaultData);

  const [isSendedVideo, seIsSendedVideo] = useState(true);
  const [orderBy, setOrderBy] = useState('popular');
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    totalPages: 1,
    total: 1,
    from: 1,
    to: 1,
  });
  const [listvideo, setListVideo] = useState<any>([]);

  const getVideo = async () => {
    const resp = await getContestVideo();
    return resp;
  };

  const handlePagination = (nro: { selected: number }) => {
    setPaginationSettings({ ...paginationSettings, current: nro.selected + 1 });
    getListContestVideos({ params: {page: nro.selected + 1, orderBy: orderBy} }).then((data) => {
      setListVideo(data.data);
      setPaginationSettings({
        current: data?.pager.current_page,
        totalPages: data?.pager.last_page,
        total: data?.pager.total,
        from: data?.pager.from,
        to: data?.pager.to,
      });
    });
  };

  const handleChangeOrder = (e: any) => {
    setOrderBy(e.target.value)
    getListContestVideos({ params: { page: paginationSettings.current, orderBy: e.target.value } }).then(
      (data) => {
        setListVideo(data.data);
        setPaginationSettings({
          current: data?.pager.current_page,
          totalPages: data?.pager.last_page,
          total: data?.pager.total,
          from: data?.pager.from,
          to: data?.pager.to,
        });
      }
    );
  };

  useEffect(() => {
    getVideo()
      .then((data) => {
        seIsSendedVideo(!!data);
      })
      .catch((error) => {
        console.error('Error:', error);
        seIsSendedVideo(true);
      });
    getListContestVideos({ params: {page: 1} }).then((data) => {
      setListVideo(data.data);
      setPaginationSettings({
        current: data?.pager.current_page,
        totalPages: data?.pager.last_page,
        total: data?.pager.total,
        from: data?.pager.from,
        to: data?.pager.to,
      });
    });
  }, []);

  return {
    elem,
    isSendedVideo,
    handlePagination,
    listvideo,
    paginationSettings,
    handleChangeOrder,
  };
}
