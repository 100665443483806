import React from 'react';
import './scss/card.scss'
import CardModal from "./modal";
import Card from "./card";
interface ListProps {
    items?: any[];
    images?: any;
    icons?: any;
    searcher?: string;
    language?: string;
    clear?: boolean;
}

const List = ({
                  items = [],
                  images = {},
                  icons = {},
                  searcher = "",
                  language = "esp",
                  clear = false
              }: ListProps) => {   const [activeCards, setActiveCards]=React.useState<any>([]);
    const [selectedItem, setSelectedItem]=React.useState<any>(null);
    const [openModal, setOpenModal]=React.useState<boolean>(false);
    React.useEffect(()=>{
        if(selectedItem){
            setOpenModal(true)
        }
    },[selectedItem])
    React.useEffect(()=>{
        if(clear){
            setActiveCards([])
        }
    },[clear])

    const closeModal = () => {
        setOpenModal(false);
        setSelectedItem(null);
    }
    const handleClick = (card:number) => {
        if(!activeCards.includes(card)){
            const item=items?.find((item:any)=>Number(item.id)===card)
            setSelectedItem(item)
        }
        const index = activeCards.indexOf(card);
        if (index !== -1) {
            const nuevosActivos = [...activeCards];
            nuevosActivos.splice(index, 1);
            setActiveCards(nuevosActivos);
        } else {
            setActiveCards([...activeCards, card]);
        }
    };
    const sortedItems:any = items.length && items.sort((a:any, b:any) => a.position - b.position) ;

    const filteredItems:any = searcher ? sortedItems.filter((item:any) => item.category.id === searcher) : sortedItems;

    return (
        <div className="container p-0">
            <div className="row align-items-center justify-content-center">
                <div className="col-12 culture justify-content-center p-0">
                    {filteredItems.filter((item:any) => item.status === "true").map((item:any,index:number) => (
                        <Card
                            key={`${index}-${item?.id}`}
                            item={item}
                            index={Number(`${item?.id}`)}
                            activeCards={activeCards}
                            language={language}
                            handleClick={handleClick}
                            images={images}
                            icons={icons}
                        />
                    ))}
                </div>
            </div>
            <CardModal item={selectedItem} visible={openModal} images={images} icons={icons} language={language} closeModal={()=>closeModal()}/>
        </div>
    );
};

export default List;