import {
  APP_URL,
  fontMX,
} from './environment';

interface Map {
  [key: string]: string | undefined;
}

//CONFIGURE DEFAULT
function setObjectCustom(color: string, font: string): any {
  return {
    color: color,
    fonts: font,
  };
}

//CONFIGURE CUSTOM COLORS && FONTS
export const domain_country = (str: string) => {
  return setObjectCustom('main-mx', fontMX);
};
export const getDomain = (str: string) => {
  localStorage.setItem('str', str);
  return {
    policy:
      'https://api-stage.brewtech-abi.com/sites/default/files/2023-08/POLITICA%20DE%20PRIVACIDAD_%20brewtech%20agosto.pdf',
    terms:
      'https://api-stage.brewtech-abi.com/sites/default/files/2023-08/T%26C%20BREWTECH%20SITE%20%20AGOSTO.pdf',
    country: 'CO',
    countryGammification:'MEX',
    name: 'Brewtech',
    appURL: APP_URL,
    marks: 'Reconocimientos',
    titlePrograms: 'ConÃ³celos aquÃ­',
    programs: 'Nuestros programas',
    descPrograms: '',
    diversityTitle: 'Diversidad, Equidad e inclusiÃ³n',
    diversityMedia: {
      video: `${process.env.PUBLIC_URL}/resources/diversity_pe.mp4`,
      info: `https://i.ibb.co/NSvMw8P/Ingografia-Diversidade-Inclusion.jpg`,
    },
    linkSupport: 'https://soporte.somosmaz.com/pqr/SomosModelo',
    description: {
      id: 'description-LOCAL',
      content: 'Esta es la descripcion de LOCAL',
    },
    supportLink: '',
  };
};

export const equalURL = (str: string, str2: string) => {
  var str1 = str.substring(0, str.length - 1);
  return new RegExp(str1, 'i').test(str2);
};
