// Components
import Header from '../../../header';
import LoadSpinner from '../../../load-spinner/load-spinner';

// Controller
import { useAddVideoContest } from '../../../../controllers';

// Modules
import { GetBodyInnerHTML } from '../../../../modules/services/getElements.service';

import { gtmCampainVideosViewPage, gtmCampainVideosSend, gtmCampainVideosEnableSend } from '../../../../tools/datalayers';

import React, { useEffect } from 'react';

export function AddVideoContest() {
  
  /*useEffect( () => {
    console.log('is send:'+ isSended)
    if( !isSended ){
      gtmCampainVideosViewPage()
      console.log("entro y gtmCampainVideosViewPage")
    }
  }, [])*/
  
  const {
    formik,
    isDragging,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleFileChange,
    isSended,
    handleCloseModal,
    isOpenModal,
    elem,
    messageModal,
  } = useAddVideoContest();

  return (
    <article className='add-video_contest'>
      <Header
        data={elem?.content.find((el: any) => el.type === 'header')?.content}
      />
      <section className='add-video_contest--form'>
        <div className='add-video_contest--text'>
          <div className='description'>
            <GetBodyInnerHTML
              className='description'
              content={
                elem?.content.find(
                  (el: any) =>
                    el.type === 'text' && !el.content.value.includes('<label')
                )?.content?.value || ''
              }
            />
          </div>

          {!isSended && (
            <form className='form-video_contest' onSubmit={formik.handleSubmit}>
              <div className='container-form'>
                <label className={`${formik.errors.name ? 'is-error' : ''}`}>
                  Título del video
                </label>
                <input
                  type='text'
                  id='name'
                  name='name'
                  className={`form-input ${
                    formik.errors.name ? 'is-error' : ''
                  }`}
                  placeholder='En BrewTech...'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className='errors-form'>{formik.errors.name}</div>
                ) : null}
              </div>
              <div className='container-form'>
                <label
                  className={`${formik.errors.description ? 'is-error' : ''}`}
                >
                  Descripción del video
                </label>
                <input
                  type='text'
                  id='description'
                  name='description'
                  className={`form-input ${
                    formik.errors.description ? 'is-error' : ''
                  }`}
                  placeholder='Escribe tu respuesta'
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className='errors-form'>{formik.errors.description}</div>
                ) : null}
              </div>
              <div
                className={`container-form ${isDragging ? 'drag-over' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <label className={`${formik.errors.file ? 'is-error' : ''}`}>
                  Sube tu video aquí*
                </label>
                <input
                  type='file'
                  id='file'
                  name='file'
                  className={`form-file ${
                    formik.errors.file ? 'is-error' : ''
                  }`}
                  placeholder='Escribe tu respuesta'
                  accept='.avi, .mp4, .mov'
                  max='25000000'
                  onChange={handleFileChange}
                />
                <label htmlFor='file' className='label-file'>
                  <img
                    src={`${process.env.PUBLIC_URL}/resources/contest/icon-download.svg`}
                    alt='Carga de video'
                    loading='lazy'
                  />

                  {formik.values.file ? (
                    <p>Archivo seleccionado: {formik.values.file.name}</p>
                  ) : (
                    <p>
                      Sube tu video arrastrando y soltando o{' '}
                      <span>haciendo clic para subir.</span>
                    </p>
                  )}
                </label>
                {!formik.errors.file && !formik.touched.file && (
                  <p className='form-description'>
                    Máx. 200 MB en formato AVI, MP4, MOV
                  </p>
                )}
                {formik.errors.file && formik.touched.file ? (
                  <div className='errors-form'>{formik.errors.file}</div>
                ) : null}
              </div>
              <div className='form-check'>
                <input
                  type='checkbox'
                  id='acceptTerms'
                  name='acceptTerms'
                  className='form-check-input'
                  onChange={formik.handleChange}
                  onClick={() => {}}
                />
                <GetBodyInnerHTML
                  className='form-check-label'
                  content={
                    elem?.content.find(
                      (el: any) =>
                        el.type === 'text' &&
                        el.content.value.includes('<label')
                    )?.content?.value || ''
                  }
                />
                {formik.errors.acceptTerms && formik.touched.acceptTerms ? (
                  <div className='errors-form'>{formik.errors.acceptTerms}</div>
                ) : null}
              </div>

              <button className='btn' type='submit' disabled={formik.isSubmitting}>
                Enviar
                <svg
                  width='32'
                  height='33'
                  viewBox='0 0 32 33'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.33464 16.5L26.668 16.5M26.668 16.5L18.668 24.5M26.668 16.5L18.668 8.5'
                    stroke='black'
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </button>
            </form>
          )}
        </div>
        <div className="flex-1">
          <img
            src={elem?.content.find((el: any) => el.type === 'infografia')?.content}
            alt='tarjetas de regalo'
            loading='lazy'
            className='add-video_contest--image'
          />
        </div>
      </section>
      <div className={`modal-overlay ${isOpenModal ? 'modal-open' : 'hidden'}`}>
        <div className='modal'>
          <button className='close-button' onClick={handleCloseModal}>
            <img
              src={`${process.env.PUBLIC_URL}/resources/contest/icon-close.svg`}
              alt='Exito'
              loading='lazy'
            />
          </button>
          <div className='modal-content'>
            <div>
              <img
                src={messageModal.success ? `${process.env.PUBLIC_URL}/resources/contest/icon-check.svg`: `${process.env.PUBLIC_URL}/resources/contest/icon-alert.svg`}
                alt='Exito'
                loading='lazy'
                className='mx-auto mb-4'
              />
            </div>
            <div>
              <h3 className="text-center" dangerouslySetInnerHTML={{__html: messageModal.message}}></h3>
            </div>
          </div>
        </div>
      </div>
      {formik.isSubmitting && <LoadSpinner/>}
    </article>
  );
}
