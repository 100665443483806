export const getGtmArgs = (category: string, action: string, label: string = "", interaction: string, name: string, text: string, userId?: string,) => {
    return {
        gtmId: process.env.REACT_APP_API_GTM_ID,
        dataLayer: {
            event: "GAEvent",
            event_category: category,
            event_action: action,
            event_label: label,
            interaction,
            component_name: name,
            element_text: text,
            userId
        }
    }
}