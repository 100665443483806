import {
  deleteSession,
  getUserData,
} from '../../api/userSession';
import React, { useEffect, useState } from 'react';
import { store } from '../../modules/appState/store/store';
import { Nav, NavItem, NavLink as MenuLink } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { apiMainURL } from '../../config/environment';
import { domain_country } from '../../config/domain';

import './Menu.scss';
import LoadSpinner from '../load-spinner/load-spinner';
import { useWindowDimensions } from '../../tools/useWindowDimensions';
import { gtmHeader, gtmLogOut, gtmProfileMenu } from '../../tools/datalayers';

const Menu = () => {
  const storeApp = store;
  const [user2, setUser2] = useState({
    firstName: '',
    lastName: '',
    image: storeApp.getState().AppReducer,
  });
  const [isData, setIsData] = useState(false);
  
  function logout() {
    deleteSession();
  }

  const { width } = useWindowDimensions()

  const [domain] = useState(
    domain_country(window.location.hostname + ''),
  );

  useEffect(() => {
    if (!isData)
      getUserData(`${apiMainURL}/api/authenticated-user`, '')
        .then((data) => {
          let d_id = data?.status?.drupal_id || '';
          localStorage.setItem('drupal_id', d_id);
          let name = data?.profile?.first_name || '';
          localStorage.setItem('user', name);
          localStorage.setItem('user_info', JSON.stringify(data?.profile));
          storeApp.dispatch({
            type: storeApp.getState().AppReducer || '',
          });
          setUser2({
            firstName: data?.profile?.first_name || '',
            lastName: data?.profile?.last_name || '',
            image: data?.profile?.user_image,
          });
          setIsData(true);
        })
        .catch((error) => {
          console.log('error', error);
          deleteSession(window.location.href);
        });
  }, [user2]);

  if (isData)
    return (
      <header className="header" id="header">
        <div className="lateralmenu">
          <div
            className="offcanvas offcanvas-start"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel" data-bs-scroll="true"
          >
            <div className="offcanvas-header">
              <img
                alt="Logo"
                src={`${process.env.PUBLIC_URL}/customs/${domain.color}/logo_menu.png`}
              />
              <button
                type="button"
                className="btn-close btn-close-white text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <Nav navbar>
              <NavItem data-bs-dismiss="offcanvas" onClick={() => gtmHeader('Sobre nosotros')}>
                <NavLink to="/about-us" className="nav-link" >
                  <span>Sobre nosotros</span>
                </NavLink>
              </NavItem>
              <NavItem data-bs-dismiss="offcanvas" onClick={() => gtmHeader('Careers')}>
                <NavLink to="/careers" className="nav-link">
                  <span>Careers</span>
                </NavLink>
              </NavItem>
              <NavItem data-bs-dismiss="offcanvas">
                <NavLink to="/profile" className="nav-link" onClick={() => gtmHeader('Menú de usuario')}>
                  <img
                    alt="Perfil"
                    src={`${process.env.PUBLIC_URL}/customs/${domain.color}/icon-profile.svg`}
                  />
                  <span>Perfil</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>

        <div>
          <div
            className="offcanvas profile-menu offcanvas-start"
            id="offcanvasExample1"
            aria-labelledby="offcanvasExampleLabel" data-bs-scroll="true"
          >
            <div className="offcanvas-header">
              <span className='black'></span>
              <button
                type="button"
                className="btn-close btn-close-white text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="profile-menu-img-container">
              <img className='profile-menu-img' src={user2.image} />
              <span>{user2.firstName} {user2.lastName}</span>
            </div>
            <Nav navbar>
              <NavItem data-bs-dismiss="offcanvas">
                <NavLink to="/profile" className="nav-link" onClick={() => gtmProfileMenu('Mi perfil')}>
                  <img
                    alt="Perfil"
                    src={`${process.env.PUBLIC_URL}/customs/${domain.color}/icon-profile.svg`}
                  />
                  <span>Mi perfil</span>
                </NavLink>
              </NavItem>
              <NavItem className="last-item" data-bs-dismiss="offcanvas" onClick={gtmLogOut}>
                <MenuLink onClick={logout} className="nav-link">
                  <img
                    alt="Perfil"
                    src={`${process.env.PUBLIC_URL}/customs/${domain.color}/icon-left-arrow.svg`}
                  />
                  <span>Cerrar sesión</span>
                </MenuLink>
              </NavItem>
            </Nav>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col px-0 py-2 d-md-none">
              <MenuLink
                className="icon-menu"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <i className="bi bi-list nav_icon"></i>
              </MenuLink>
            </div>
            <div className="col justify-content-between">
              <span className="logo-header">
                <NavLink to="/landing">
                  <img
                    alt="logo"
                    src={`${process.env.PUBLIC_URL}/customs/${domain.color}/logo_h.png`}
                  />
                </NavLink>
              </span>
            </div>
            <div className="col">
              <div className="row align-items-center justify-content-end">
                <div className="col-auto hidden d-md-block">
                  <NavLink to="/about-us" className="nav-link" onClick={() => gtmHeader('Sobre nosotros')}>
                    <span>Sobre Nosotros</span>
                  </NavLink>
                </div>
                <div className="col-auto hidden d-md-block">
                  <NavLink to="/careers" className="nav-link" onClick={() => gtmHeader('Careers')}>
                    <span>Careers</span>
                  </NavLink>
                </div>
                <div className="col-auto">
                  <div className="img-perfil">
                    {width > 768 && (
                      <NavLink to="/profile" className="nav-link" onClick={() => gtmHeader('Menú de usuario')}>
                        <i className="bi-person-circle" />
                      </NavLink>
                    )}
                    {width <= 768 && (
                      <MenuLink
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                        data-bs-target="#offcanvasExample1"
                        role="button"
                        aria-controls="offcanvasExample"
                        onClick={() => gtmHeader('Menú de usuario')}
                      >
                        <i className="bi-person-circle" />
                      </MenuLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  else return <LoadSpinner />
};

export default Menu;

export const NotificationsComponent = () => {
  return (
    <div>
      <notifications-button-component></notifications-button-component>
    </div>
  );
};
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'notifications-button-component': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
