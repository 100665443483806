import React, { useRef, useState } from 'react';
import './MediaComponent.scss';
import { gtmCloseVideo, gtmVideo } from '../../tools/datalayers';
import LeaderModal from '../modal';
import { apiMainURL } from '../../config/environment';

type MediaComponentProps = {
  content: {
    type: 'video' | 'infografia' | 'image';
    src: string;
  }[];
  hasCarousel?: boolean;
};

const VideoPreview = ({ video }: any) => {
  const { title, src } = video;
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<any>(null);
  const handlePlay = () => {
    setIsPlaying(true);

    // Reproducir el video automáticamente
    if (videoRef) {
      videoRef?.current?.play();

      // Pausar el video después de 1 segundo
      setTimeout(() => {
        setIsPlaying(false);
        videoRef?.current?.pause();
      }, 100);
    }

  };
  return (
    <div className="video-preview">
      <div className="video-preview-icon-container">
        {src?.preview && (

          <video src={src?.src} id="main-video"  ref={videoRef} controls onClick={handlePlay} className='video-img-preview' poster={apiMainURL + src?.preview} muted></video>
        )}
        {!src?.preview && (

          <video src={src?.src} id="main-video"  ref={videoRef} onClick={handlePlay} controls muted className='video-img-preview ' poster={`${process.env.PUBLIC_URL}/customs/main-mx/icon-play-video.svg`}></video>
        )}
      </div>
      {/* <span>{title}</span> */}
    </div>
  )

}

const MediaComponent = ({
  content,
}: MediaComponentProps) => {
  const [activeVideo, setActiveVideo] = useState<any>(false);
  const [activeImage, setActiveImage] = useState<any>(false);

  const handleVideo = (video: any) => {
    gtmVideo(video?.title)
    setActiveVideo(video)
  }

  return (
    <>
      {content?.slice(0, 1)?.map((element: any, index: number) => {
        return (
          <div
            key={index}
            className="multimedia-zone"
          >
            {element.type === 'video' && (
              <div className="multimedia-item primary-video" onClick={() => handleVideo(element)}>
                <VideoPreview video={element} />
              </div>
            )}
            {(element.type === 'infografia' || element.type === 'image') && (
              <div className="multimedia-item" onClick={() => setActiveImage(element)}>
                <img
                  key={index}
                  src={element.src}
                  alt="Infografía"
                  className="rounded img-fluid center img-center primary-image"
                />
              </div>
            )}
          </div>
        );
      })}
      <div className="row">
        {content?.slice(1)?.map((element: any, index: number) => {
          return (
            <div
              key={index}
              className="col col-6 col-md-4 col-lg-3"
            >
              {element.type === 'video' && (
                <div className="multimedia-item" onClick={() => handleVideo(element)}>
                  <VideoPreview video={element} />
                  {/* <video
                            muted
                            src={element?.src}
                            style={{ width: '100%', height: '15rem', backgroundColor: '#000000' }}
                            loop
                            controls={false}
                            preload='metadata'
                        /> */}
                </div>
              )}
              {(element.type === 'infografia' || element.type === 'image') && (
                <div className="multimedia-item" onClick={() => setActiveImage(element)}>
                  <img
                    key={index}
                    src={element.src}
                    alt="Infogr"
                    className="rounded img-fluid center"
                  />
                </div>
              )}
            </div>
          );
        })}
        <LeaderModal video={activeVideo} imageSelected={activeImage} onClose={() => {
          gtmCloseVideo(activeVideo?.title)
          setActiveVideo(false)
          setActiveImage(false)
        }} />
      </div>
    </>
  );
}

export default MediaComponent