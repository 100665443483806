import { useHistory } from "react-router-dom";
import { GetBodyInnerHTML } from "../../modules/services/getElements.service";
import { gtmCareers, gtmCommunities, gtmFuture, gtmOffices, gtmOurPeople } from "../../tools/datalayers";

const futureStyles = [
    {
        background: '#F9B700',
        buttonBackground: '#000000',
        buttonTextColor: '#FFFFFF',
    },
    {
        background: '#000000',
        text: '#FFFFFF',
    },
    {
        background: '#FFFFFF',
    },
]

const FutureCard = (props: any) => {
    const history = useHistory()
    const { leader, type, cardStyles, modal, variant = 'future', item, onClick = () => { } } = props;
    const { title, description, link, image } = item || {}
    const styles = cardStyles ? cardStyles[type % cardStyles.length] : futureStyles[type % futureStyles.length]
    const location=window.location.href;
    const knowMore = () => {
        if(location?.includes('nuestragente')){
            gtmOurPeople('Nuestra gente');
        }
        if (variant === 'future') {
            gtmFuture(title)
        } else if (variant === 'offices') {
            gtmOffices(title)
        } else if (variant === 'communities') {
            gtmCommunities(title)
        } else if (variant === 'people') {
            gtmOurPeople(title)
        } else if (variant === 'careers') {
            gtmCareers(title)
        }

       if( link?.uri.split(':')[0]=='internal'){
        history.push(link?.uri.split(':')[1])
       }
       else{
        window.open(link?.uri)
       }
    }
    return (
        <div className={modal?'future-card leader-4':'future-card des-min-height'} style={{ backgroundColor: styles.background }} onClick={onClick}>
            <img
                alt={title}
                src={image}
            />
            <div className={modal?'future-card-content pointer min-content-leader':"future-card-content"}>
                <p className="future-card-title" style={{ color: styles.text ?? '#000000' }}>
                    {title}
                </p>
                <span className={!modal?"future-card-desc":"future-card-desc fs-15"} style={{ color: styles.text ?? '#000000' }}>
                    <GetBodyInnerHTML content={description} />
                    {leader && item?.email && <div className="leader-info" style={{ color: styles.text ?? '#000000' }}>
                            <img
                                alt='Email'
                                src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-email.svg`}
                            />
                            {item?.email}
                        </div>}
                        {leader && item?.phone && <div className="leader-info" style={{ color: styles.text ?? '#000000' }}>
                            <img
                                alt='Teléfono'
                                src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-phone.svg`}
                            />
                            {item?.phone}
                        </div>}
                </span>
                
                {!modal && link?.value && (
                    <button
                        className='btn-black'
                        style={{ backgroundColor: styles.buttonBackground ?? '#F9B700', color: styles.buttonTextColor ?? '#000000' }}
                        onClick={knowMore}
                    >
                        {link.value}
                    </button>
                )}
            </div>
        </div>
    )
}

export default FutureCard