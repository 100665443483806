import React from 'react';
import { getDomain, domain_country } from '../../config/domain';
import { gtmFooter } from '../../tools/datalayers';
const FooterTerms = (props: any) => {
  const domainData = getDomain(window.location.hostname + '');
  const domain = domain_country(window.location.hostname + '');

  return (
    <div>
      <footer>
        {/* <a target="_blank" rel="noreferrer" href={domainData.policy} onClick={() => gtmFooter('Legal')}>
          Legal
        </a> */}
        <a target="_blank" rel="noreferrer" href={domainData.terms} onClick={() => gtmFooter('Términos y condiciones')}>
          Términos y condiciones
        </a>
        <a target="_blank" rel="noreferrer" href={domainData.policy} onClick={() => gtmFooter('Políticas de privacidad')}>
          Políticas de privacidad
        </a>
        <div className="row">
          <img
            alt="logo"
            src={`${process.env.PUBLIC_URL}/customs/${domain.color}/logos-footer.png`}
          />
        </div>
      </footer>
    </div>
  );
};
export default FooterTerms;
