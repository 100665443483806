
import React, { useEffect, useState } from 'react';
import {
  subscribeEmailNewsletter,
  useGetFutureSectionHomeQuery,
  useGetHomeSliderQuery,
  useGetLastNewsHomeQuery,
} from '../../api/userSession';
import { store } from '../../modules/appState/store/store';
import {
  GetBodyInnerHTML,
  bannersInSection,
  getBasicNewsFeed,
  getHomeSection,
} from '../../modules/services/getElements.service';
import LoadSpinner from '../load-spinner/load-spinner';
import 'react-multi-carousel/lib/styles.css';
import FutureCard from './FutureCard';
import { gtmEmailSaved, gtmFeatured, gtmNewsletter, gtmSendEmail } from '../../tools/datalayers';
import { useHistory } from 'react-router-dom';
import Slider from '../slider';
import SectionCard from '../sectionCard';
import { SolveLinks } from '../../modules/links/solve_links';
import { getDomain } from '../../config/domain';

export const Landing = () => {
  const storeApp = store;
  const [successAlert, setSuccessAlert] = useState<boolean>(false)

  const [user, setUser] = useState<any>(localStorage.getItem('user_info'));
  const slider = useGetHomeSliderQuery('PRI').data
  const featured = useGetHomeSliderQuery('SEC').data
 
  const news = useGetLastNewsHomeQuery('').data
  const future = useGetFutureSectionHomeQuery('FUT').data
  const offices = useGetFutureSectionHomeQuery('OFI').data

  const sliderItems = bannersInSection(slider).sort(((a: any, b: any) => a.order - b.order));
  const featuredItems = bannersInSection(featured).sort(((a: any, b: any) => a.order - b.order));
  const newsItems = getBasicNewsFeed(news);
  const futureSection = getHomeSection(future)
  const officesSection = getHomeSection(offices)

  storeApp.subscribe(() => {
    if (localStorage.getItem('user_info')) {
      setUser(JSON.parse(localStorage.getItem('user_info') || ''));
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [featuredItems,sliderItems])

  const newsStyles = [
    {
      background: '#F9B700',
      buttonBackground: '#000000',
      buttonTextColor: '#FFFFFF',
    },
    {
      background: '#C0C0C0',
    },
    {
      background: '#000000',
      text: '#FFFFFF',
    },
    {
      background: '#F1EFEF',
    },
  ]

  const FeaturedSection = (props: any) => {
    const { item } = props
    console.log(item)
    const { title, subtitle, caption, link, content, description } = item
    const knowMore = () => {
      gtmFeatured('Charlas con Clarissa')
      window.open(link?.uri)
    }

    return (
      <div className="featured">
        {content?.type=='infografia' &&(
           <img
          alt="Contenido destacado"
          src={content?.content}
        />
        )}
        {content?.type=='video' &&(
         
           <video
               autoPlay
               muted
               loop
               controls={false}
               src={content?.content}
               style={{ width: '100%', height: '20rem', backgroundColor: '#000000' }}
           />
        )}
       
        <div className="featured-content">
          <div className="featured-title">
            <div className='disclaimer'>Destacado</div>
            <span className="title-small">{title}</span>
            <span className="title-big">{subtitle}</span>
          </div>
          <div className="featured-desc">
            <span>
              <GetBodyInnerHTML content={caption ?? description} />
            </span>
            <button className='btn-black' onClick={knowMore}>Conoce más</button>
          </div>
        </div>
      </div>
    )
  }

  const LastNewsSection = () => (
    <div className="last-news">
      <div className="last-news-header">
        <div className="last-news-img">
          <img
            alt="Últimas noticias"
            src={`${process.env.PUBLIC_URL}/customs/main-mx/last-news-img.svg`}
          />
        </div>
        <div className="last-news-title">
          <span className="title-small">Últimas</span>
          <span className="title-big">Noticias</span>
        </div>
      </div>
      <div className="last-news-items">
        {newsItems.map((n, i) => (
          <NewsCard key={n.id} type={i} news={n} />
        ))}
      </div>
    </div>
  )

  const NewsCard = (props: any) => {
    const { type, news } = props;
    const { title, desc, link } = news;
    const styles = newsStyles[type % newsStyles.length]
    const isLarger = (type % 5) < 2
    const history = useHistory()

    const knowMore = () => {
      gtmNewsletter(title)
      history.push(link.uri)
    }

    return (
      <div className={`news-card ${isLarger && 'news-card-larger'}`} style={{ backgroundColor: styles.background }}>
        <span className="news-card-title" style={{ color: styles.text ?? '#000000' }}>
          {title}
        </span>
        <div className="news-card-content" style={{ color: styles.text ?? '#000000' }}>
          <span>
            <GetBodyInnerHTML content={desc} />
          </span>
          <button
            style={{ backgroundColor: styles.buttonBackground ?? '#F9B700', color: styles.buttonTextColor ?? '#000000' }}
            onClick={knowMore}
          >
            +
          </button>
        </div>
      </div>
    )
  }

  const NewsletterSection = () => {
    const [email, setEmail] = useState({ value: '', isValid: true })
    const [termsChecked, setTermsChecked] = useState<boolean>(false)
    const [marketingChecked, setMarketingChecked] = useState<boolean>(false)
    const domainData = getDomain(window.location.hostname + '');

    const handleEmail = (value: string) => {
      const found = value.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)
      setEmail({ value, isValid: found?.length === 1 })
    }

    const send = () => {
      gtmSendEmail()
      subscribeEmailNewsletter(email.value, marketingChecked)
        .then((res) => {
          if (res?.status === "success") {
            setSuccessAlert(true)
            gtmEmailSaved(user.id)
          }
        })
    }

    return (
      <div className="newsletter">
        <div className="newsletter-desc">
          <img
            alt='Brewtech'
            src={`${process.env.PUBLIC_URL}/customs/main-mx/logo_newsletter.png`}
          />
          <div className="newsletter-title">
            <span className='title-light'> ¡Forma parte </span>
            <span className="title-bold">de una comunidad apasionada </span>
            <span className="title-bold">por la tecnología cervecera!</span>
          </div>
        </div>
        <form className="newsletter-field">
          <span className="label">Suscríbete al newsletter de la Vicepresidencia BrewTech y mantente al día con las actualizaciones exclusivas sobre nuestros proyectos, lanzamientos y una variedad de temas que seguro serán de tu interés. 
¡Te esperamos con contenido emocionante y relevante!

Ingresa tu correo electrónico aquí
</span>
          <input type="email" className="newsletter-input" placeholder='Escribe tu correo' onChange={(e) => handleEmail(e.target.value)} />
          {!email.isValid && (
            <>
              <span className='error'>Por favor revisa tu correo e intenta nuevamente</span>
              <br />
            </>
          )}
          <div>
            <div className="newsletter-checkbox">
              <input type="checkbox" id="terms" name="terms" checked={termsChecked} onClick={() => setTermsChecked(!termsChecked)} />
              <label>
              He leído, entiendo y acepto los  <a href={domainData.terms} target="_blank">Términos y Condiciones</a> y la <a href={domainData.policy} target="_blank">política de protección de datos personales</a>, para el procesamiento de mi información por parte de BrewTech, con la finalidad del uso requerido que está descrito en la mencionada política.
              </label>
            </div>
            <div className="newsletter-checkbox">
              <input type="checkbox" id="marketing" name="marketing" checked={marketingChecked} onClick={() => setMarketingChecked(!marketingChecked)} />
              <label>
              Quiero recibir el newsletter.
              </label>
            </div>
          </div>
          <button
            className="btn-secondary"
            disabled={!email.value || !email.isValid || !termsChecked}
            type='button'
            onClick={send}
          >
            Enviar
          </button>
        </form>
      </div>
    )
  }

  const OfficesSection = () => (
    <div className="offices">
      <div className="offices-title">
        <span className="title-small">{officesSection.title}</span>
        <span className="title-big">{officesSection.subtitle}</span>
      </div>
      {officesSection?.items?.length > 0 && (
        <Slider
          dotColor='white'
          responsive={{
            desktop: {
              breakpoint: { max: 5000, min: 768 },
              items: 2,
              slidesToSlide: 2
            },
            mobile: {
              breakpoint: { max: 768, min: 0 },
              items: 1,
              slidesToSlide: 1
            }
          }}>
          {officesSection.items.sort((a: any, b: any) => a.order - b.order).map((office: any, idx: number) => (
            <FutureCard key={office.title} variant='offices' type={idx} item={office} />
          ))}
        </Slider>
      )}
    </div>
  )

  if (user) {
    return (
      <div>
        <section className="main-content">
          {successAlert && (
            <div className="success-alert">
              <img
                src={`${process.env.PUBLIC_URL}/customs/main-mx/check-success.svg`}
                alt="Exitoso"
              />
              <span>
                Tu correo fue almacenado exitosamente.
                ¡Pronto recibiras nuestro newsletter!
              </span>
              <img
                src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-close.svg`}
                alt="Cerrar"
                onClick={() => setSuccessAlert(false)}
              />
            </div>
          )}
          <div>
            <div className="banner-home">
              {sliderItems?.length > 0 && (
                <Slider className='home-slider'>
                  {sliderItems?.sort((a: any, b: any) => a.order - b.order).map((b:any, index:number) => {
                  
                    
                      if(b?.content?.type=='infografia'){
                     
                         return (
                        <img key={index} src={b.content?.content} alt={b.content?.content} />
                      )
                      }
                      if(b?.content?.type=='video'){
                     
                         return (
                          <video
                          autoPlay
                            loop
                            controls={false}
                            muted
                          src={b?.content?.content}
                          style={{ width: '100%', height: '100%', backgroundColor: '#000000' }}
                      />
                      )
                      }
                   
                  })}
                </Slider>
              )}
            </div>
            {featuredItems?.length > 0 && (
              <Slider dotColor='white' className='home-slider'>
                {featuredItems.sort((a: any, b: any) => a.order.order).map(item =>{
                       return (
                        <FeaturedSection key={item.altText} item={item} />
                    )
                    }
                
                
                )}
              </Slider>
            )}
            <LastNewsSection />
            <SectionCard section={{ ...futureSection, style: '2' }} />
            <NewsletterSection />
            <OfficesSection />
          </div>
        </section>
      </div>
    );
  }
  else {
    return <LoadSpinner />
  }
};
