/* 
export const apiMainURL = 'http://beer-ambassador-apidev.52.248.91.44.nip.io';
export const ssoURL="https://auth-dev.somosmaz.com/oauth/authorize"
export const appURL="http://localhost:3000"

 */

export const apiMainURL = process.env.REACT_APP_API_URL;
export const ssoURL = process.env.REACT_APP_SSO_URL;
export const gamificationURL = process.env.REACT_APP_GAMIFICATION_URL;
export const appURL = process.env.REACT_APP_APP_URL;
export const appUrlHB = process.env.REACT_APP_PLA_HB_URL;
export const appUrlWU = process.env.REACT_APP_PLA_WU_URL;
export const appUrlDM = process.env.REACT_APP_PLA_DM_URL;
export const appUrlEI = process.env.REACT_APP_PLA_EI_URL;
export const appUrlBF = process.env.REACT_APP_PLA_BF_URL;
export const appUrlNT = process.env.REACT_APP_PLA_NT_URL;
export const appUrlMG = process.env.REACT_APP_PLA_MG_URL;

//Custom Countries
export const APP_URL_EC = process.env.REACT_APP_APP_URL_EC;
export const APP_URL_MX = process.env.REACT_APP_APP_URL_MX;
export const APP_URL_PE = process.env.REACT_APP_APP_URL_PE;
export const APP_URL = process.env.REACT_APP_APP_URL;

export const fontMX = 'Montserrat';
export const fontPE = 'Montserrat';
