export function IconSend({ color = '#000000' }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.3076 0.15141L0.585349 12.6833C-0.262918 13.1707 -0.155128 14.3517 0.688453 14.7079L5.67027 16.7981L19.1347 4.9317C19.3925 4.70206 19.7581 5.05355 19.5378 5.32068L8.24787 19.0757V22.8484C8.24787 23.9544 9.58354 24.3903 10.2397 23.5889L13.2156 19.9662L19.0551 22.4126C19.7206 22.6938 20.4798 22.2767 20.6016 21.5596L23.976 1.31368C24.1353 0.366992 23.1183 -0.317246 22.3076 0.15141Z'
        fill={color}
      />
    </svg>
  );
}
