import React, { useEffect, useState } from 'react';
import { FacebookShareButton } from 'react-share';
import { useHistory } from 'react-router-dom';
import { subscribeEmailNewsletter, useGetDefaultContentCategoryQuery, useGetDefaultContentQuery } from '../../api/userSession';
import {
  GetBodyInnerHTML,
  getBasicDefaultContent,
  getDefaultContent,
} from '../../modules/services/getElements.service';
import LoadSpinner from '../load-spinner/load-spinner';
import MediaComponent from '../mediaComponent/media-component';
import Sections from '../sections/sections';
import { Document, Page } from 'react-pdf';
// Worker
import { gtmAboutUs, gtmOurPeople, gtmRelatedArticle, gtmSendEmail, gtmSharepoint, gtmSocialMedia } from '../../tools/datalayers';
import Slider, { SliderItem } from '../slider';
import Header from '../header';
import SectionCard from '../sectionCard';
import RelatedArticle from '../header/RelatedArticle';
import { getDomain } from '../../config/domain';
import Swal from 'sweetalert2'
const Newsletter = (props: any) => {
  const [email, setEmail] = useState({ value: '', isValid: true })
  const [termsChecked, setTermsChecked] = useState<boolean>(false)
  const [marketingChecked, setMarketingChecked] = useState<boolean>(false)
  const domainData = getDomain(window.location.hostname + '');
  const { onSave } = props
  const [successAlert, setSuccessAlert] = useState<boolean>(false)
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  
  const handleEmail = (value: string) => {
    const found = value.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)
    setEmail({ value, isValid: found?.length === 1 })
  }
    useEffect(() => {
      
    }, [successAlert]);
  const send = () => {
    gtmSendEmail()
    subscribeEmailNewsletter(email.value, marketingChecked)
      .then((res) => {
        if (res?.status === "success") {
          Toast.fire({
            icon: 'success',
            title: 'Tu correo fue almacenado exitosamente. ¡Pronto recibiras nuestro newsletter!'
          })
          // onSave && onSave(true)
        }
      })
  }

  return (
    <div className="newsletter-default">
      <div className="newsletter-text">
        <span className='title'>Entérate de novedades</span>
        <span className="description">Suscríbete al newsletter de la Vicepresidencia BrewTech y mantente al día con las actualizaciones exclusivas sobre nuestros proyectos, lanzamientos y una variedad de temas que seguro serán de tu interés. ¡Te esperamos con contenido emocionante y relevante! Ingresa tu correo electrónico aquí</span>
      </div>
      <form className="newsletter-field">
        <input type="email" className="newsletter-input" placeholder='tumail@mail.com' onChange={(e) => handleEmail(e.target.value)} />
        {!email.isValid && (
          <>
            <span className='error'>Por favor revisa tu correo e intenta nuevamente</span>
            <br />
          </>
        )}
        <div>
          <div className="newsletter-checkbox">
            <input type="checkbox" id="terms" name="terms" checked={termsChecked} onClick={() => setTermsChecked(!termsChecked)} />
            <label>
            He leído, entiendo y acepto los <a href={domainData.terms} target="_blank">Términos y Condiciones</a> y la <a href={domainData.policy} target="_blank">política de protección de datos personales</a>, para el procesamiento de mi información por parte de BrewTech, con la finalidad del uso requerido que está descrito en la mencionada política.
            </label>
          </div>
          <div className="newsletter-checkbox">
            <input type="checkbox" id="marketing" name="marketing" checked={marketingChecked} onClick={() => setMarketingChecked(!marketingChecked)} />
            <label>
            Quiero recibir el newsletter.
            </label>
          </div>
        </div>
      </form>
      <button
        className="btn-secondary"
        disabled={!email.value || !email.isValid || !termsChecked}
        type='button'
        onClick={send}
      >
        Conoce más
      </button>
    </div>
  )
}

const RelatedContent = (props: any) => {
  const { id, exclude } = props
  const defaultData = useGetDefaultContentCategoryQuery({ id, excludeId: exclude }).data ;
  const elem: any = getBasicDefaultContent(defaultData);
  const history = useHistory()

  const openUrl = (data: any) => {
    gtmRelatedArticle(`${data?.title}`);
    history.push(elem?.link)
  }
useEffect(() => {
  
}, [history, props, elem]);
  if (defaultData!=null && elem) {
    return (
      <div>
        <p className="related-section-title">
          Otros artículos
        </p>
        {elem?.map((el: any, index: number) => {
            return <RelatedArticle key={index} data={el} openUrl={() => openUrl(el)} />
        })
        }
      </div>
    )
  }

  return(
    <div>
    <p className="related-section-title pt-5">
     No hay contenido relacionado
    </p>
   
  </div>
  )
}

export const Default = (props: any) => {
  const { id } = props;
  console.log(id ||( window?.location?.href?.split('?')[1])?.split('#')[0])
  const defaultData = useGetDefaultContentQuery(id ||( window?.location?.href?.split('?')[1])?.split('#')[0])?.data;
  const history = useHistory()
  const [successAlert, setSuccessAlert] = useState<boolean>(false)

  const elem: any = getDefaultContent(defaultData);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [category, setCategory] = useState<string | null>(null);
const location=window.location.href;
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  const removeAccents = (str:string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 
  const copy = async () => {
    handleSocialMedia("Copiar enlace")
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(encodeURI(window.location.href));
    } else {
      return document.execCommand('copy', true, encodeURI(window.location.href));
    }
  }
  useEffect(() => {
setTimeout(() => {
  const hash = window.location.hash;

  if (hash) {
    const targetElement = document.querySelector(hash);
    if (targetElement) {
      const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offset-60,
        behavior: 'smooth',
      });
    }
  }
}, 2000);
   
  }, [window.location.href]);
  function onChangePage(event: string) {
    switch (event) {
      case '+':
        if (pageNumber + 1 <= numPages)
          setPageNumber(pageNumber + 1);
        break;
      case '-':
        if (pageNumber - 1 > 0)
          setPageNumber(pageNumber - 1);
        break;
    }

  }


  const goToOurPeople = (link: any) => {
    gtmAboutUs('Nuestra gente')
    history.push(link.uri)
  }


  const handleSharepoint = () => {
    const articleName = elem?.content?.find((el: any) => el.type === 'header')
    gtmSharepoint(`${articleName?.content?.title} ${articleName?.content?.subtitle}`)
  }

  const handleSocialMedia = (socialMedia: string) => {
    const articleName = elem?.content?.find((el: any) => el.type === 'header')
    gtmSocialMedia(socialMedia, `${articleName?.content?.title} ${articleName?.content?.subtitle}`)
  }

  if (defaultData?.data && elem?.content)
    return (
      <div>
        <section className="main-content">
          <div className="container-fluid">
            {successAlert && (
              <div className="success-alert">
                <img
                  src={`${process.env.PUBLIC_URL}/customs/main-mx/check-success.svg`}
                  alt="Exitoso"
                />
                <span>
                  Tu correo fue almacenado exitosamente.
                  ¡Pronto recibiras nuestro newsletter!
                </span>
                <img
                  src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-close.svg`}
                  alt="Cerrar"
                  onClick={() => setSuccessAlert(false)}
                />
              </div>
            )}
            <div className="row">
              <div className="col-12 px-0">
                {elem?.content?.map((el: any, index: number) => {
                  if (el?.type === 'header') {
                    return (
                      <Header key={index} data={el?.content} />
                    )
                  }
                  if (el?.type === 'slider') {
                    return (
                      <Slider key={index}>
                        {el?.content.sort((a: any, b: any) => a.order - b.order).map((slider: any) => (
                          <SliderItem key={slider.title} slider={slider} onClick={() => goToOurPeople(slider.link)} />
                        ))}
                      </Slider>
                    )
                  }
                })}
              </div>
              <div className={`default-content-container ${id === 'sobre-nosotros' ? 'px-0' : ''}`}>
              <div className={ (defaultData?.data[0]?.attributes?.field_content_relation || defaultData?.data[0]?.attributes?.field_show_form_news)?'withOutRelated':'withRelated'}>
                  {elem?.content?.map((el: any, index: number) => {
                    if (el?.type === 'links') {
                      return <Sections key={index} content={el?.content} />;
                    }
                    if (el?.type === 'media') {
                      return (
                        <MediaComponent
                          key={index}
                          content={el?.content}
                        />
                      );
                    }
                    if (el?.type === 'text') {
                      return (
                        <div key={index} className='text-generic'>
                          <GetBodyInnerHTML
                            className="text-generic-r my-5"
                            content={el?.content?.value || ''}
                          />
                        </div>
                      );
                    }
                    if (el?.type === 'title') {
                      switch (el?.content?.type) {
                        case 'Título 1':
                          return (
                            <h1 key={index} className="section-title" id={removeAccents(el?.content?.value?.toLowerCase()?.trimEnd()?.replace(/ /g, "-") || '')}>
                              {el?.content?.value || ''}
                            </h1>
                          );
                        case 'Título 2':
                          return (
                            <h2 key={index} className="section-title" id={removeAccents(el?.content?.value?.toLowerCase()?.trimEnd()?.replace(/ /g, "-") || '')}>
                              {el?.content?.value || ''}
                            </h2>
                          );
                        case 'Título 3':
                          return (
                            <h3 key={index} className="section-title">
                              {el?.content?.value || ''}
                            </h3>
                          );
                        case 'Título 4':
                          return (
                            <h4 key={index} className="section-title">
                              {el?.content?.value || ''}
                            </h4>
                          );
                        case 'Título 5':
                          return (
                            <h5 key={index} className="section-title">
                              {el?.content?.value || ''}
                            </h5>
                          );
                      }
                    }
                    if (el?.type === 'separator') {
                      return (
                        <div key={index} style={{ paddingTop: el?.content?.px }}>
                          <p className="oculto">{'   separator    '}</p>
                        </div>
                      );
                    }
                    if (el?.type === 'infografia') {
                      return (
                        <div key={index} className="multimedia-item">
                          <MediaComponent
                            key={index}
                            content={[{ type: 'infografia', src: el?.content }]}
                          />
                        </div>
                      );
                    }
                    if (el?.type === 'ficha') {

                      return (
                        <div key={index} className='sharepoint' onClick={handleSharepoint}>
                          <a href={el?.content?.link} target="_blank">
                            <img src={el?.content?.image} />
                            <span>{el?.content?.description}</span>
                          </a>
                        </div>
                      )
                    }
                    if (el?.type === 'sectionCard') {
                      return (
                        <SectionCard key={el?.content?.title} section={el?.content} />
                      )
                    }
                    if (el?.type === 'pdf' && el?.content) {
                      return (
                        <div key={index} className="row">
                          <div className='mx-auto'>
                            <Document className='mx-auto' file={{ url: el?.content || '' }} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
                              <Page pageNumber={pageNumber} />
                            </Document>
                            <p className='pdf-controls mx-auto'>
                              Página {pageNumber} de {numPages}
                            </p>
                            <div className="pdf-controls mx-auto mb-5">
                              <button className={pageNumber === 1 ? 'btn btn-secondary mx-auto disabled' : 'btn btn-secondary mx-2'} onClick={() => onChangePage('-')}>Anterior </button>
                              <button className={pageNumber === numPages ? 'btn btn-secondary mx-auto disabled' : 'btn btn-secondary mx-2'} onClick={() => onChangePage('+')}>Siguiente</button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    if (el.type === 'relatedContent') {
                      !category && setCategory(el.content)
                    }
                  }
                  )}
                  {defaultData?.data[0]?.attributes?.field_share_content && (
                    <div className='share-section'>
                      <div className='share-section-title'>
                        Compartir en:
                      </div>
                      <FacebookShareButton url={encodeURI(window.location.href)} onClick={() => handleSocialMedia('Facebook')}>
                        <img
                          alt="Facebook"
                          src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-facebook.svg`}
                        />
                      </FacebookShareButton>
                      <a href={`mailto:?subject=Brewtech&amp;body=${encodeURI(window.location.href)}`}
                        title="Compartir por email" onClick={() => handleSocialMedia('Email')}>
                        <img
                          alt="Email"
                          src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-share-email.svg`}
                        />
                      </a>
                      <img
                        alt="Copiar enlace"
                        src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-copy-link.svg`}
                        onClick={copy}
                      />
                    </div>
                  )}
                </div>


                {/* MOBILE VERSION */}

                {/* <div className="mobile-size" style={{
                  width:'100%'
                }}>
                  {elem?.content?.map((el: any, index: number) => {
                    if (el?.type === 'links') {
                      return <Sections key={index} content={el?.content} />;
                    }
                    if (el?.type === 'media') {
                      return (
                        <MediaComponent
                          key={index}
                          content={el?.content}
                        />
                      );
                    }
                    if (el?.type === 'text') {
                      return (
                        <div key={index} className='text-generic'>
                          <GetBodyInnerHTML
                            className="text-generic-r my-5"
                            content={el?.content?.value || ''}
                          />
                        </div>
                      );
                    }
                    if (el?.type === 'title') {
                      switch (el?.content?.type) {
                        case 'Título 1':
                          return (
                            <h1 key={index} className="section-title" id={el?.content?.value?.toLowerCase()?.replace(/ /g, "-") || ''}>
                              {el?.content?.value || ''}
                            </h1>
                          );
                        case 'Título 2':
                          return (
                            <h2 key={index} className="section-title">
                              {el?.content?.value || ''}
                            </h2>
                          );
                        case 'Título 3':
                          return (
                            <h3 key={index} className="section-title">
                              {el?.content?.value || ''}
                            </h3>
                          );
                        case 'Título 4':
                          return (
                            <h4 key={index} className="section-title">
                              {el?.content?.value || ''}
                            </h4>
                          );
                        case 'Título 5':
                          return (
                            <h5 key={index} className="section-title">
                              {el?.content?.value || ''}
                            </h5>
                          );
                      }
                    }
                    if (el?.type === 'separator') {
                      return (
                        <div key={index} style={{ paddingTop: el?.content?.px }}>
                          <p className="oculto">{'   separator    '}</p>
                        </div>
                      );
                    }
                    if (el?.type === 'infografia') {
                      return (
                        <div key={index} className="multimedia-item">
                          <MediaComponent
                            key={index}
                            content={[{ type: 'infografia', src: el?.content }]}
                          />
                        </div>
                      );
                    }
                    if (el?.type === 'ficha') {
                      return (
                        <div key={index} className='sharepoint' onClick={handleSharepoint}>
                          <a href={el?.content?.link} target="_blank">
                            <img src={el?.content?.image} />
                            <span>{el?.content?.description}</span>
                          </a>
                        </div>
                      )
                    }
                    if (el?.type === 'sectionCard') {
                      return (
                        <SectionCard key={el?.content?.title} section={el?.content} />
                      )
                    }
                    if (el?.type === 'pdf' && el?.content) {
                      return (
                        <div key={index} className="row">
                          <div className='mx-auto'>
                            <Document className='mx-auto' file={{ url: el?.content || '' }} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
                              <Page pageNumber={pageNumber} />
                            </Document>
                            <p className='pdf-controls mx-auto'>
                              Página {pageNumber} de {numPages}
                            </p>
                            <div className="pdf-controls mx-auto mb-5">
                              <button className={pageNumber === 1 ? 'btn btn-secondary mx-auto disabled' : 'btn btn-secondary mx-2'} onClick={() => onChangePage('-')}>Anterior </button>
                              <button className={pageNumber === numPages ? 'btn btn-secondary mx-auto disabled' : 'btn btn-secondary mx-2'} onClick={() => onChangePage('+')}>Siguiente</button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    if (el.type === 'relatedContent') {
                      !category && setCategory(el.content)
                    }
                  }
                  )}
                  {defaultData?.data[0]?.attributes?.field_share_content && (
                    <div className='share-section'>
                      <div className='share-section-title'>
                        Compartir en:
                      </div>
                      <FacebookShareButton url={encodeURI(window.location.href)} onClick={() => handleSocialMedia('Facebook')}>
                        <img
                          alt="Facebook"
                          src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-facebook.svg`}
                        />
                      </FacebookShareButton>
                      <a href={`mailto:?subject=Brewtech&amp;body=${encodeURI(window.location.href)}`}
                        title="Compartir por email" onClick={() => handleSocialMedia('Email')}>
                        <img
                          alt="Email"
                          src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-share-email.svg`}
                        />
                      </a>
                      <img
                        alt="Copiar enlace"
                        src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-copy-link.svg`}
                        onClick={copy}
                      />
                    </div>
                  )}
                </div> */}
                {
                  (defaultData?.data[0]?.attributes?.field_content_relation || defaultData?.data[0]?.attributes?.field_show_form_news) && <div className='related-section'>
                    {defaultData?.data[0]?.attributes?.field_content_relation && <RelatedContent id={category} exclude={defaultData?.data[0]?.attributes?.drupal_internal__nid} />}
                    {defaultData?.data[0]?.attributes?.field_show_form_news && <Newsletter />}
                  </div>
                }
              </div>
            </div>
          </div >
        </section >
      </div >
    );
  else return <LoadSpinner />;
};
