import { Link } from 'react-router-dom';

// Components
import Header from '../../../header';
import ListVideo from '../components/ListVideo';

// Controller
import { useVideoContest } from '../../../../controllers';

// Modules
import { GetBodyInnerHTML } from '../../../../modules/services/getElements.service';

import { gtmCampainVideosViewPage } from '../../../../tools/datalayers';

export function ListVideoContest() {
  const {
    elem,
    isSendedVideo,
    handlePagination,
    listvideo,
    paginationSettings,
    handleChangeOrder,
  } = useVideoContest();

  return (
    <article className='video_contest'>
      <Header
        data={elem?.content.find((el: any) => el.type === 'header')?.content}
      />
      <section className='video_contest--container'>
        <div className='description'>
          <GetBodyInnerHTML
            className='description'
            content={
              elem?.content.find(
                (el: any) =>
                  el.type === 'text' && !el.content.value.includes('<label')
              )?.content?.value || ''
            }
          />
        </div>
        {!isSendedVideo && (
          <div className='w-max mx-auto'>
            <Link to='/embajador-mision/carga'>
              <button className='btn-link' onClick={() => gtmCampainVideosViewPage()}>
                Subir video
                <span>+</span>
              </button>
            </Link>
          </div>
        )}
      </section>
      <ListVideo handlePagination={handlePagination} listvideo={listvideo} paginationSettings={paginationSettings} handleChangeOrder={handleChangeOrder} />
    </article>
  );
}
