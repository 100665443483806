import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Default } from '../default-section/default';

const NewsDetail = () => {
    const { newsId } = useParams<any>()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Default id={newsId} />
    );
};
export default NewsDetail;
