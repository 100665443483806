import React, { useState } from 'react';
import { GetBodyInnerHTML } from '../../modules/services/getElements.service';
import FutureCard from '../home/FutureCard';
import LeaderModal from '../modal';
import { gtmAboutUsModal } from '../../tools/datalayers';

const SectionCard = (props: any) => {
    const { section } = props
    const { title, subtitle, description, style, items, hasButton, hasModal, link } = section

    const [activeLeader, setActiveLeader] = useState<any>(false)

    return (
        <div className={`future ${style !== '2' && 'bg-white'}`}>
            <div className="future-header">
                <span className="title-small">{title}</span>
                <span className="title-big">{subtitle}</span>
                <span className="description">
                    <GetBodyInnerHTML content={description} />
                </span>
            </div>
            <div className={hasModal?"future-items":"future-items "}>
                {items?.length > 0 && (
                    items.map((card: any, i: number) => (
                        <FutureCard variant={(title + subtitle).toLowerCase().includes("somos") ? 'future' : 'communities'} key={card.title} type={i} item={card} onClick={() => {
                            hasModal && setActiveLeader(card)
                        }} leader={true} modal={hasModal}/>
                    ))
                )}
                {hasButton && <button className='btn-secondary'>Conoce más</button>}
                {hasModal && <LeaderModal leader={activeLeader} onClose={() => {
                    gtmAboutUsModal()
                    setActiveLeader(false)
                }} />}
            </div>
        </div>
    )
};

export default SectionCard;
