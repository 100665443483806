import React from 'react';
import './scss/help.scss';
interface HelpProps {
    data?: any[];
    language?: string;
    config?: any;
    images?: any;
    icons?: any;
    back?: () => void;
}

const Help = ({
                  data = [],
                  language = "spa",
                  config = {},
                  images = {},
                  icons = {},
                  back = () => {}
              }: HelpProps) => {
    const sortedData = data.sort((a:any, b:any) => a.position - b.position);
    const [activos, setActivos] = React.useState<any>([]);

    const handleClick = (numero:any) => {
        const index = activos.indexOf(numero);
        if (index !== -1) {
            const nuevosActivos = [...activos];
            nuevosActivos.splice(index, 1);
            setActivos(nuevosActivos);
        } else {
            setActivos([...activos, numero]);
        }
    };
    return (
        <>
            <div className="accordion-cards">
                <div className="accordion-cards-header">
                    <div className="accordion-cards-header-title">
                        <button onClick={()=>{back()}}>
                            <img src={icons?.back}></img>
                        </button>
                        {config.helpBack[language]}
                    </div>
                    <img className="d-sm-block d-md-none accordion-cards-header-image" src={icons?.help}></img>
                </div>
                <div className="accordion-cards-content container">
                    <div className="row justify-content-center">
                        {sortedData.map((item, index) => (
                            <div key={index} className="col-md-8 col-sm-12 p-0 mb-4 accordion-cards-content-container"  style={{backgroundImage:`url("${activos.includes(index)?images.accordeonExpandBG:images.accordeonBG}")`}} onClick={()=>{handleClick(index)}}>
                                <div className={`accordion-cards-content-container-title ${activos.includes(index)?"active":""}`}>
                                    {item.title[language]}
                                    <img src={`${activos.includes(index)?icons.accordeonExpand:icons.accordeon}`} alt=""/>
                                </div>
                                {activos.includes(index) &&
                                    <div className="accordion-cards-content-container-expand">
                                        <div dangerouslySetInnerHTML={{ __html: item.content[language] }} />
                                        {item.images && (
                                            <div className="row justify-content-center accordion-cards-content-container-expand-images">
                                                <div className="col d-flex justify-content-center flex-wrap">
                                                    {item.images.map((image: any, index1: any) => (
                                                        <img key={index1}  src={image} alt={`Image ${index1 + 1}`} className={`img-fluid align-self-center image-${index1 + 1}-1`}  />
                                                    ))}
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                }
                            </div>
                        ))}

                    </div>
                </div>
            </div>

        </>

    );
};

export default Help;
