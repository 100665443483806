import React, { useEffect } from 'react';
import FutureCard from '../home/FutureCard';
import { useGetDefaultContentQuery } from '../../api/userSession';
import { GetBodyInnerHTML, getDefaultContent } from '../../modules/services/getElements.service';
import { Default } from '../default-section/default';
import MediaComponent from '../mediaComponent/media-component';

const careerStyles = [
  {
    background: '#00F579',
    buttonBackground: '#000000',
    buttonTextColor: '#FFFFFF',
  },
  {
    background: '#7226E9',
    text: '#FFFFFF',
    buttonBackground: '#000000',
    buttonTextColor: '#FFFFFF',
  },
]

const Careers = () => {
  const defaultData = useGetDefaultContentQuery('careers')?.data;
  const elem: any = getDefaultContent(defaultData);
  const careersData: any = elem?.content?.filter((item: any) => item?.type === 'sectionCard')[0]?.content
  const careersInfografia: any = elem?.content?.filter((item: any) => item?.type === 'infografia');
  const careersTexto: any = elem?.content?.filter((item: any) => item?.type === 'infografia');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='careers'>
      <div className="bg-hyb">
       <div className="container-fluid">
       <section className="main-content">
          <div className="future careers pb-0">
            <div className="future-header">
              <div className="careers-header">
                <img
                  alt="Careers"
                  src={`${process.env.PUBLIC_URL}/customs/main-mx/careers-header.png`}
                />
              </div>
            </div>
          </div>
        </section>
       </div>

      </div>
      <section className="">
        <div className="future careers">
          <div className="future-header">
            <span className="description white">
              <GetBodyInnerHTML content={careersData?.description} />
            </span>
          </div>
          <div className="future-items cards-center">
            {
              careersInfografia?.map((item: any, index: number) => {
                return (
                  <div key={index} className="multimedia-item pt-3">
                    <MediaComponent
                      key={index}
                      content={[{ type: 'infografia', src: item?.content }]}
                    />
                  </div>
                )
              }
              )
            }
            {careersData?.items?.map((item: any, i: number) => {
              return (
               
                  <FutureCard variant='careers' key={item.title} item={item} cardStyles={careerStyles} type={i} />
                
                
              )
            })}


          </div>
        </div>
      </section>
    </div>
  );
};
export default Careers;
