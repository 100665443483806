import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useGetDefaultContentQuery } from '../../../../api/userSession';
import { postContestVideo, getContestVideo } from '../../../../api/contest';

import { getDefaultContent } from '../../../../modules/services/getElements.service';
import { gtmCampainVideosCloseModal, gtmCampainVideosEnableSend } from '../../../../tools/datalayers';

import { gtmCampainVideosSend } from '../../../../tools/datalayers';

interface FormValues {
  file: File | undefined;
  acceptTerms: boolean;
  description: string;
  name: string;
}

export function useAddVideoContest() {
  const [isDragging, setIsDragging] = useState(false);
  const [isSended, seIsSended] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState({success: true, message: ''});
  const defaultData = useGetDefaultContentQuery('embajador-mision-carga')?.data;
  const elem: any = getDefaultContent(defaultData);
  let flagDatalayer=false;
  useEffect(() => {
  
  }, [flagDatalayer]);
 const executeDL=()=>{
  if(!flagDatalayer){
    gtmCampainVideosEnableSend();
    flagDatalayer=true;
    setTimeout(() => {
     flagDatalayer=false;
    }, 5000);
  }
 }
  const videoSchema = Yup.object().shape({
    acceptTerms: Yup.bool()
      .required('Terminos y Condiciones Son Requeridos')
      .oneOf([true], 'Terminos y Condiciones Son Requeridos')
      .test('dataLayer', '', async (value) => {
        if (!value && !flagDatalayer) {
          // Coloca aquí la lógica del dataLayer
          await executeDL();
        }

        return true; // Devuelve true para indicar que la validación ha pasado
      }),
     
    name: Yup.string()
      .required('Campo requerido')
      .nullable()
      .default(undefined)
      .transform((curr, orig) => (orig === undefined ? '' : curr))
      .test('dataLayer', '', async (value) => {
       await setTimeout(async () => {
        if (!value && !flagDatalayer) {
          // Coloca aquí la lógica del dataLayer
          await executeDL();
        }
       }, 800);
        return true; // Devuelve true para indicar que la validación ha pasado
      }),
    description: Yup.string()
      .required('Campo requerido')
      .nullable()
      .default(undefined)
      .transform((curr, orig) => (orig === undefined ? '' : curr))
      .test('dataLayer', '', async (value) => {
       await setTimeout(async () => {
        if (!value && !flagDatalayer) {
          await executeDL();
        }
       }, 1600);
        return true; // Devuelve true para indicar que la validación ha pasado
      }),

    file: Yup.mixed()
      .required('Campo requerido')
      .test('fileSize', 'El archivo es demasiado grande.', async (value) => {
       await setTimeout(async () => {
        if (!value && !flagDatalayer) {
          await executeDL();
        }
       }, 2400);

        return value && value.size <= 200 * 1024 * 1024;
      })
      .test('fileFormat', 'Formato de archivo no válido.', async (value) => {
       await setTimeout(async () => {
        if (!value && !flagDatalayer) {
          // Coloca aquí la lógica del dataLayer
          await executeDL();
        }
       }, 3200);

        return value && /\.(avi|mp4|mov)$/.test(value.name.toLowerCase());
      }),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      acceptTerms: false,
      description: '',
      name: '',
      file: undefined,
    },
    validationSchema: videoSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const isValid = await formik.validateForm();
      if (isValid) {
        if (values.file) {
          formData.append('video', values.file);
        }
        formData.append('title', values.name);
        formData.append('description', values.description);
  
        await postContestVideo(formData).then((response) => {
          if (response.uid) {
            seIsSended(true);
            setIsOpenModal(true);
            setMessageModal({success: true, message: "¡Tu vídeo se ha<br/>enviado con éxito!"});
            gtmCampainVideosSend();
          } else if (response.error) {
            setIsOpenModal(true);
            setMessageModal({success: false, message: response.error});
          }
        });// Forzar el envío del formulario
      } else {
        executeDL();
      }
      
    },
    validateOnChange: false, 
  });

  const handleFileChange = (e: any) => {
    formik.setFieldValue('file', e.currentTarget.files[0]);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files?.[0];
    formik.setFieldValue('file', file);
  };

  const getVideo = async () => {
    const resp = await getContestVideo();
    return resp;
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    gtmCampainVideosCloseModal();
  };

  useEffect(() => {
    getVideo()
      .then((data) => {
        seIsSended(!!data);
      })
      .catch((error) => {
        console.error('Error:', error);
        seIsSended(true);
      });
  }, []);

  return {
    formik,
    isDragging,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleFileChange,
    isSended,
    handleCloseModal,
    isOpenModal,
    elem,
    messageModal,
  };
}
