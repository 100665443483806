import React from 'react';
import './scss/modal.scss'
import Card from "./card";

interface ModalProps {
    item: any;
    images: any;
    icons: any;
    language: string;
    visible: boolean;
    closeModal: () => void;
}

const Modal = ({
                   item = {},
                   images = {},
                   icons = {},
                   language = "esp",
                   visible = false,
                   closeModal = () => {}
               }: ModalProps) => {


    return (
        <>
            {visible && <div className="card-modal">
                <div className="modal-content">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col w-max d-flex align-items-center justify-content-center position-relative modal-content-info">
                                {
                                    item.category.id === '2' && item.image.detail[language].length > 0 ? (
                                            <div className={`modal-content-data-title-group`}>
                                                {item.subtitle.first && <div
                                                    className={`modal-content-data-title-small`}>{item.subtitle.first[language]}</div>}
                                                {item.title && <div
                                                    className={`modal-content-data-title-big`}>{item.title[language]}</div>}
                                                <img src={item.image.detail[language][0]}
                                                     className="modal-content-img-number" alt=""/>

                                            </div>
                                        ) :
                                        <>
                                            <img className="modal-content-img" src={images.cartasWhite[language]} alt=""/>
                                            <div className="spacer-info"/>

                                        </>
                                }
                                <Card
                                    key={item?.id}
                                    item={item}
                                    index={0}
                                    activeCards={[]}
                                    language={language}
                                    handleClick={()=>{}}
                                    images={images}
                                    icons={icons}
                                    fullScreen={true}
                                >
                                    <img onClick={closeModal} className="modal-close position-absolute" src={icons.closeModal} alt=""/>
                                </Card>
                            </div>

                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default Modal;