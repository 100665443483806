
export function IconArrow({color = '#000000'}) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
    <g clipPath="url(#clip0_2441_32814)">
      <path d="M8.64062 2.60449L3.28125 7.96387L8.64062 13.3232L7 14.9639L0 7.96387L7 0.963867L8.64062 2.60449Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_2441_32814">
        <rect width="14" height="14" fill="white" transform="matrix(1 0 0 -1 0 14.6768)"/>
      </clipPath>
    </defs>
  </svg>
}