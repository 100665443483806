import { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  getListContestVideos,
  getListReviewsVideos,
  postVoteVideo,
  postReviewVideo,
  getDetailContestVideo,
} from '../../../../api/contest';

interface FormValues {
  review: string;
}

export function usePreviewVideoContest() {
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const divRef = useRef<HTMLDivElement | null>(null);

  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    totalPages: 1,
    total: 1,
    from: 1,
    to: 1,
  });
  const [listvideo, setListVideo] = useState<any>([]);
  const [listReviews, setListReviews] = useState<any>([]);
  const [canVoteVideo, setCanVoteVideo] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState({
    success: true,
    message: '',
  });
  const [videoDetail, setVideoDetail] = useState({
    title: '',
    description: '',
    video: '',
    votes: 0,
    user: {
      user_image: null,
      name: '',
    },
  });

  const reviewSchema = Yup.object().shape({
    review: Yup.string()
      .required('Campo requerido')
      .nullable()
      .default(undefined)
      .transform((curr, orig) => (orig === undefined ? '' : curr)),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      review: '',
    },
    validationSchema: reviewSchema,
    onSubmit: async (values) => {
      await postReviewVideo(params.id, values.review).then((response) => {
        if (response === 'ok') {
          setIsOpenModal(true);
          setMessageModal({
            success: true,
            message: '¡Tu comentario se ha<br/>enviado con éxito!',
          });
          formik.resetForm();
          getListReviewsVideos(params.id).then((data) => {
            setListReviews(data);
          });
        } else {
          setIsOpenModal(true);
          setMessageModal({
            success: false,
            message: response.error,
          });
        }
      });
    },
  });

  const handlePagination = (nro: { selected: number }) => {
    setPaginationSettings({ ...paginationSettings, current: nro.selected + 1 });
    getListContestVideos({ params: { page: nro.selected + 1 } }).then(
      (data) => {
        setListVideo(data.data);
        setPaginationSettings({
          current: data?.pager.current_page,
          totalPages: data?.pager.last_page,
          total: data?.pager.total,
          from: data?.pager.from,
          to: data?.pager.to,
        });
      }
    );
  };

  const handleChangeOrder = (e: any) => {
    getListContestVideos({
      params: { page: paginationSettings.current, orderBy: e.target.value },
    }).then((data) => {
      setListVideo(data.data);
      setPaginationSettings({
        current: data?.pager.current_page,
        totalPages: data?.pager.last_page,
        total: data?.pager.total,
        from: data?.pager.from,
        to: data?.pager.to,
      });
    });
  };

  const handleVoteVideo = () => {
    postVoteVideo(params.id).then((data) => {
      if (!data.error) {
        setVideoDetail({ ...videoDetail, votes: data });
        setCanVoteVideo(false);
        setIsOpenModal(true);
        setMessageModal({
          success: true,
          message: '¡Tu voto se ha<br/>enviado con éxito!',
        });
      } else {
        setIsOpenModal(true);
        setMessageModal({ success: false, message: data.error });
      }
    });
  };

  const goHome = () => {
    history.push('/landing');
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    getDetailContestVideo(params.id).then((data) => {
      setVideoDetail(data);
      setCanVoteVideo(data.voteEnable);
    });
    getListContestVideos({ params: { page: 1, id: params.id } }).then(
      (data) => {
        setListVideo(data.data);
        setPaginationSettings({
          current: data?.pager.current_page,
          totalPages: data?.pager.last_page,
          total: data?.pager.total,
          from: data?.pager.from,
          to: data?.pager.to,
        });
      }
    );
    getListReviewsVideos(params.id).then((data) => {
      setListReviews(data);
    });
  }, [params.id]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [listReviews]);

  return {
    goHome,
    handlePagination,
    listvideo,
    paginationSettings,
    handleChangeOrder,
    videoDetail,
    handleVoteVideo,
    canVoteVideo,
    listReviews,
    formik,
    isOpenModal,
    handleCloseModal,
    messageModal,
    divRef,
  };
}
