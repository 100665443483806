import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getUserData } from '../../api/userSession';
import { apiMainURL } from '../../config/environment';
import { store } from '../../modules/appState/store/store';
import CropImage from './crop-image';
import { gtmChageProfilePhoto } from '../../tools/datalayers';

class ImageUpload extends Component {
  storeApp = store;
  state = {
    file: '',
    imagePreviewUrl: '',
    modal: false,
  };

  componentDidMount() {
    this.storeApp.subscribe(() => {
      this.setState({
        ...this.state,
        modal: false,
        imagePreviewUrl: this.storeApp.getState().AppReducer || '',
      });
    });
    getUserData(`${apiMainURL}/api/authenticated-user`, '').then((data) => {
      this.setState({
        ...this.state,
        imagePreviewUrl: data?.profile?.user_image || '',
      });

      if (data?.profile?.position_name)
        localStorage.setItem('position', data?.profile?.position_name || '');
    });
  }
  _handleSubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      'authorization',
      `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
    );
    var formdata = new FormData();
    formdata.append('sharp', localStorage.getItem('sharp')?.toString() || '');
    formdata.append('image', this.state.file);
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    fetch(`${apiMainURL}/api/picture_user`, requestOptions)
      .then((response) => response.text())
      .then(() => {
        getUserData(`${apiMainURL}/api/authenticated-user`, '').then((data) => {
          this.setState({
            ...this.state,
            imagePreviewUrl: data?.profile?.user_image || '',
          });
          this.storeApp.dispatch({
            type: data?.profile?.user_image || '',
          });
        });
      })
      .catch((error) => console.log('error', error));
  };

  _handleImageChange = (e: any) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = this._handleReaderLoaded;
    reader.readAsBinaryString(file);
  };
  _handleReaderLoaded = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    this.setState({ ...this.state, file: btoa(binaryString) });
    this._handleSubmit();
  };
  toggle = () => {
    gtmChageProfilePhoto()
    this.setState({ ...this.state, modal: !this.state.modal });
  };

  render() {
    let { imagePreviewUrl } = this.state;

    return (
      <div className="images-profile">
        <form onSubmit={this._handleSubmit}>
          <div className="image-form">
            <div className="content-image">
              {imagePreviewUrl && (
                <img src={imagePreviewUrl} className="img-fluid" />
              )}
            </div>
            <button
              type="button"
              onClick={this.toggle}
              className='change-image-btn'
            >
              <span>Cambiar</span>
            </button>
          </div>
        </form>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="dialogImage modal-dialog-centered"
        >
          <ModalHeader toggle={this.toggle}>
            Actualizar tu Foto de perfil
          </ModalHeader>
          <ModalBody>
            <CropImage idactivity={''} tour={false}></CropImage>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ImageUpload;
