import { apiMainURL } from './../../../config/environment';

export async function postContestVideo(formdata: FormData) {
  var myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`
  );

  const response = await fetch(`${apiMainURL}/api/v1/ambassador-mission`, {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
  });

  return response.json();
}

export async function getContestVideo() {
  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`
  );

  const response = await fetch(`${apiMainURL}/api/v1/ambassador-mission`, {
    method: 'GET',
    headers: myHeaders,
  });

  return response.json();
}

export async function getListContestVideos({
  params,
}: {
  params: {
    page: number;
    orderBy?: string;
    id?: string;
    [key: string]: number | string | undefined;
  };
}) {
  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`
  );

  const urlWithParams = new URL(
    `${apiMainURL}/api/v1/ambassador-mission/videos`
  );
  Object.keys(params).forEach((key) =>
    urlWithParams.searchParams.append(key, params[key]?.toString() || '')
  );

  const response = await fetch(urlWithParams, {
    method: 'GET',
    headers: myHeaders,
  });

  return response.json();
}

export async function getDetailContestVideo(id: string) {
  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`
  );

  const response = await fetch(
    `${apiMainURL}/api/v1/ambassador-mission/videos/${id}`,
    {
      method: 'GET',
      headers: myHeaders,
    }
  );

  return response.json();
}

export async function getListReviewsVideos(id: string) {
  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`
  );

  const response = await fetch(`${apiMainURL}/api/v1/ambassador-mission/comments/${id}`, {
    method: 'GET',
    headers: myHeaders,
  });

  return response.json();
}

export async function postReviewVideo(id: string, review: string) {
  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`
  );

  const response = await fetch(`${apiMainURL}/api/v1/ambassador-mission/comments/${id}`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      comment: review
    })
  });

  return response.json();
}

export async function postVoteVideo(id: string) {
  var myHeaders = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`
  );

  const response = await fetch(`${apiMainURL}/api/v1/ambassador-mission/votes/${id}`, {
    method: 'POST',
    headers: myHeaders,
  });

  return response.json();
}
