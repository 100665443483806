import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { domain_country, getDomain } from './config/domain';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './scss/App.scss';
import Callback from './components/CallBack/callback';
import PageError from './components/error/error';
import FooterTerms from './components/footer-terms/footer-terms';
import { Landing } from './components/home/landing';
import LandingUserWithSession from './components/home/LandingUserWithoutSession';
import { Legal } from './components/legal/legal';
import { Cards } from './components/Cards/cards';
import LoadSpinner from './components/load-spinner/load-spinner';
import LateralMenu from './components/menu/menu';
import { Profile } from './components/profile/profile';
import { Default } from './components/default-section/default';
import AboutUs from './components/aboutUs';
import Careers from './components/careers';
import NewsDetail from './components/newsDetail';
import {AddVideoContest, ListVideoContest, PreviewVideoContest} from './components/contest';

const App = () => {
  const [accept, setAccept] = useState('-1');
  const [domain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const [domainData, setDomainData] = useState(
    getDomain(window.location.hostname + ''),
  );
  const root = () => {
    setDomainData(getDomain(window.location.hostname + ''));
    document.documentElement.style.setProperty(
      '--font-family-mon',
      domain.fonts,
    );
    document.title = domainData.name || '';
  };
  useEffect(() => {
    root();
    if (localStorage.getItem('code_verifier')) {
      if (localStorage.getItem('localToken')) {
        setAccept('1');
        localStorage.setItem(
          'accept-terms',
          '1'
        );
      }
    }
  }, []);


  if (localStorage.getItem('code_verifier')) {
    if (accept === '0') {
      return (
        <div className={domain.color}>
          <BrowserRouter forceRefresh>
            <Route path="/auth/callback" exact component={Callback} />
            <Route
              path="*"
              component={() => (
                <Legal
                  acceptTerms={(a: string) => {
                    setAccept(a);
                  }}
                />
              )}
            />
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    } else if (accept === '1') {
      return (
        <div className={domain.color}>
          <BrowserRouter>
            <LateralMenu />
            <Switch>
              <Route path="/Memons-des-ynch-Helld-the-these-much-malast-harm" exact component={PageError} />
              <Route path="/landing" exact component={Landing} />
              <Route path="/about-us" exact component={AboutUs} />
              <Route path="/careers" exact component={Careers} />
              <Route path="/news/:newsId" exact component={NewsDetail} />
              <Route path="/auth/callback" exact component={Callback} />
              <Route path="/embajador-mision" exact component={ListVideoContest} />
              <Route path="/embajador-mision/carga" exact component={AddVideoContest} />
              <Route path="/embajador-mision/:id" exact component={PreviewVideoContest} />
              <Route path="/profile" exact component={Profile} />
              <Route path="/legal" exact component={Legal} />
              <Route path="/cartas-de-cultura" exact component={Cards} />
              <Route path="/:newsId" exact component={NewsDetail} />
              <Route path="/" exact component={Landing} />
              <Route path="*" exact component={PageError} />
            </Switch>
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    } else {
      return (
        <div className={domain.color}>
          <BrowserRouter>
            <Route path="/auth/callback" exact component={Callback} />
            <Route path="*" exact component={LoadSpinner} />
          </BrowserRouter>
          <FooterTerms />
        </div>
      );
    }
  } else {
    return (
      <div className={domain.color}>
        <LandingUserWithSession />
        <FooterTerms content={domainData} />
      </div>
    );
  }
};

export default App;