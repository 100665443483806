import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  deleteSession,
  getUserData,
  updateProfile,
  useGetGenderOptionsQuery,
  useGetProfileQuery,
} from '../../api/userSession';
import { domain_country } from '../../config/domain';
import { apiMainURL } from '../../config/environment';
import { store } from '../../modules/appState/store/store';
import ImageUpload from './rotateImage';
import { Nav, NavItem, NavLink as MenuLink } from 'reactstrap';
import { gtmSaveProfileinfo } from '../../tools/datalayers';
import { getGenders, getProfileData } from '../../modules/services/getElements.service';

const DataItem = (props: any) => {
  const { label, prefix, value, isLast = false, isLocked = true } = props

  return (
    <div className={`data-item-container ${isLast ? 'last-item' : ''}`}>
      <div className='data-item'>
        <span className='data-title'>{label}</span>
        <span className='data-value'>
          {prefix && <span className='data-prefix'>{prefix}</span>}
          {value}
        </span>
      </div >
      {isLocked && <img
        alt="Campo bloqueado"
        className='icon-lock'
        src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-lock.svg`}
      />}
    </div >
  )
}

export const Profile = () => {
  const [successAlert, setSuccessAlert] = useState<boolean>(false)
  const [domain] = useState(
    domain_country(window.location.hostname + ''),
  );
  const genderOptions = useGetGenderOptionsQuery('').data
  const genders = getGenders(genderOptions)

  const [user2, setUser2] = useState({
    vp: '',
    bu: '',
    firstName: '',
    lastName: '',
    sharpId: '',
    email: '',
    image: '',
    team: '',
    globalId: '',
    birthDate: '',
    uid: '',
  });

  const profileItems = useGetProfileQuery(user2?.uid).data
  const profile = getProfileData(profileItems)
  const [phone, setPhone] = useState({ value: '', isValid: true })
  const [gender, setGender] = useState<string>('')

  const getBirthDate = (birthDate: string) => {
    const parts = birthDate.split('-')
    return { day: parts[2], month: parts[1], year: parts[0] }
  }

  const logout = () => {
    deleteSession();
  }

  useEffect(() => {
    if (profile?.gender && !gender) {
      setPhone({ ...phone, value: profile?.phone })
      setGender(profile?.gender)
    }
  }, [profile])

  useEffect(() => {
    getUserData(`${apiMainURL}/api/authenticated-user`, sharp)
      .then((data) => {
        setUser2({
          firstName: data?.profile?.first_name || '',
          lastName: data?.profile?.last_name || '',
          email: data?.profile?.email || '',
          sharpId: data?.profile?.sharp_id || '',
          vp: data?.profile?.vp || '',
          bu: data?.profile?.bu || '',
          image: data?.profile?.user_image || '',
          team: data?.profile?.team_name || '',
          globalId: data?.profile?.global_id || '',
          birthDate: data?.profile?.birth_date || '',
          uid: data?.status?.drupal_id || ''
        });

        store.dispatch({
          type: data?.profile?.user_image || '',
        });
        if (data?.profile?.position_name)
          localStorage.setItem('position', data?.profile?.position_name || '');
      })
      .catch((error) => {
        console.log('error', error);
        deleteSession(window.location.href);
      });
  }, []);
  let sharp = localStorage.getItem('sharp') || '';

  const handlePhone = (value: string) => {
    const found = value.match(/^\d{10}$/)
    setPhone({ value, isValid: found?.length === 1 })
  }

  const saveInfo = () => {
    updateProfile(phone.value, gender)
      .then((res) => {
        if (res?.updateUser) {
          setSuccessAlert(true)
        }
      })
    gtmSaveProfileinfo()
  }

  return (
    <div className='profile-container'>
      {successAlert && (
        <div className="success-alert mt-0">
          <img
            src={`${process.env.PUBLIC_URL}/customs/main-mx/check-success.svg`}
            alt="Exitoso"
          />
          <span>
            Tus datos fueron actalizados exitosamente.
          </span>
          <img
            src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-close.svg`}
            alt="Cerrar"
            onClick={() => setSuccessAlert(false)}
          />
        </div>
      )}
      <div className="profile-menu hidden d-md-block">
        <div>
          <div className="profile-menu-img-container">
            <img className='profile-menu-img content-image' src={user2.image} />
            <span>{user2.firstName} {user2.lastName}</span>
          </div>
          <Nav navbar>
            <NavItem>
              <NavLink to="/profile" className="nav-link">
                <img
                  alt="Perfil"
                  src={`${process.env.PUBLIC_URL}/customs/${domain.color}/icon-profile.svg`}
                />
                <span>Mi perfil</span>
              </NavLink>
            </NavItem>
            <NavItem className="last-item">
              <MenuLink onClick={logout} className="nav-link">
                <img
                  alt="Perfil"
                  src={`${process.env.PUBLIC_URL}/customs/${domain.color}/icon-left-arrow.svg`}
                />
                <span>Cerrar sesión</span>
              </MenuLink>
            </NavItem>
          </Nav>
        </div>
      </div>
      <section className="main-content">
        <div className="container-fluid">
          <div className='profile-title'>
            Mis datos
          </div>
          <div className='profile-content'>
            <ImageUpload />
            <div className="profile-data">
              <form>
                <DataItem label='Nombre' value={user2.firstName} />
                <DataItem label='Apellido' value={user2.lastName} />
                <DataItem label='Sharp ID' value={user2.sharpId} />
                <DataItem label='BU' value={user2.bu} />
                <DataItem label='VP' value={user2.vp} />
                <DataItem label='Equipo' value={user2.team} />
                <DataItem label='Correo electrónico' value={user2.email} />
                <DataItem label='Teléfono' prefix={'+57'} value={
                  <>
                    <input
                      className='input-phone'
                      name='phone'
                      pattern="[0-9]*"
                      type="number"
                      value={phone.value}
                      onChange={e => handlePhone(e.target.value)}
                    />
                    {!phone.isValid && (
                      <div className='data-item-error'>
                        <br />
                        <span>El teléfono debe tener diez dígitos</span>
                      </div>
                    )}
                  </>
                } isLocked={false} />
                <DataItem label='Documento' prefix={'CC'} value={user2.globalId} />
                <DataItem label='Fecha de Nacimiento' value={
                  <div className='d-flex'>
                    <div className='birthdate-item'><span>Día:</span> {getBirthDate(user2.birthDate).day}</div>
                    <div className='birthdate-item'><span>Mes:</span> {getBirthDate(user2.birthDate).month}</div>
                    <div className='birthdate-item'><span>Año:</span> {getBirthDate(user2.birthDate).year}</div>
                  </div>
                } />
                <DataItem label='Género' value={
                  <div className='gender-selector'>
                    {genders?.length > 0 && genders.map(genderOpt => (
                      <div key={genderOpt.label} className="gender-option">
                        <input
                          type="radio"
                          value={genderOpt.value}
                          name='gender'
                          checked={genderOpt.value === gender}
                          onChange={() => setGender(genderOpt.value)}
                        />
                        <label>{genderOpt.label}</label>
                      </div>
                    ))}
                  </div>
                } isLast isLocked={false} />
                <button
                  disabled={!phone.isValid}
                  className="btn-secondary"
                  type='button'
                  onClick={saveInfo}
                >
                  Guardar
                </button>
              </form>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
};
