import ReactPaginate from "react-paginate";

import { IconArrow } from "../../assets/icons";

interface PaginationProps {
    onPageChange: (nro: any) => void;
    pageCount: number;
}

export default function Pagination(props: PaginationProps) {
    const { onPageChange, pageCount } = props;
  
    return (
      <div className="pagination-brewtech">
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <div className="rotate-180">
              <IconArrow />
            </div>
          }
          onPageChange={onPageChange}
          pageRangeDisplayed={1}
          marginPagesDisplayed={2}
          activeClassName="selected"
          pageClassName="page-item"
          breakClassName="only-text"
          pageCount={pageCount}
          previousLabel={<IconArrow />}
          renderOnZeroPageCount={null}
        />
      </div>
    );
  };