// Components
import ListVideo from '../components/ListVideo';
import { IconSend } from '../../../../assets/icons';

// Controller
import { usePreviewVideoContest } from '../../../../controllers';

export function PreviewVideoContest() {
  const {
    videoDetail,
    goHome,
    handlePagination,
    listvideo,
    paginationSettings,
    handleChangeOrder,
    handleVoteVideo,
    canVoteVideo,
    listReviews,
    formik,
    isOpenModal,
    handleCloseModal,
    messageModal,
    divRef,
  } = usePreviewVideoContest();

  return (
    <article className='preview-video_contest'>
      <div className='breadcrumb'>
        <span className='black'>
          <span onClick={goHome}>Home</span>
          &nbsp;{'>'}&nbsp;
        </span>
        <span className='yellow'>Vota por un video</span>
      </div>
      <section className='preview-video_contest--container'>
        <div className='paragraph'>
          <h2>{videoDetail.title}</h2>
          <p>{videoDetail.description}</p>
          <div className='preview-video_contest--preview'>
            <div className='video'>
              <video controls src={videoDetail.video}></video>
              <div className='username'>
                <div>
                  <img
                    src={videoDetail.user.user_image ?? `${process.env.PUBLIC_URL}/resources/contest/icon-user.svg`}
                    alt={videoDetail.user.name}
                    width={40}
                    height={40}
                    className='rounded-circle'
                  />
                </div>
                <div>
                  <h4>{videoDetail.user.name}</h4>
                </div>
              </div>
            </div>
            <div className='reviews'>
              <div className='votes-container'>
                <p className='votes'>{videoDetail.votes} votos</p>
                {canVoteVideo && (
                  <button className='btn-vote' onClick={handleVoteVideo}>
                    Votar
                  </button>
                )}
              </div>
              <h3>Comentarios</h3>
              <div className='votes-container--list' ref={divRef}>
                {listReviews &&
                  listReviews.length > 0 &&
                  listReviews.map((v: any, index: number) => (
                    <div
                      className='review-container'
                      key={`list-review-${index}`}
                    >
                      <div>
                        <img
                          src={v.user.user_image ?? `${process.env.PUBLIC_URL}/resources/contest/icon-user.svg`}
                          alt={v.user.name}
                          width={40}
                          height={40}
                          className='rounded-circle'
                        />
                      </div>
                      <div>
                        <h4>{v.user.name}</h4>
                        <p>{v.comment}</p>
                      </div>
                    </div>
                  ))}
                  {listReviews &&
                  listReviews.length === 0 && <p className="text-center text-secondary">Sin Comentarios</p>}
              </div>
              <form onSubmit={formik.handleSubmit} className='position-relative'>
              <input
                  type='text'
                  id='review'
                  name='review'
                  className={`review-input ${
                    formik.errors.review ? 'is-error' : ''
                  }`}
                  placeholder='Escribe tu comentario'
                  onChange={formik.handleChange}
                  value={formik.values.review}
                />
                {formik.errors.review && formik.touched.review ? (
                  <div className='errors-form position-absolute'>{formik.errors.review}</div>
                ) : null}
                <button type='submit' className='btn-send-message' disabled={formik.isSubmitting}>
                  <IconSend />
                </button>
              </form>
            </div>
          </div>
          <h2>Otros Videos</h2>
        </div>
      </section>
      <ListVideo
        handlePagination={handlePagination}
        listvideo={listvideo}
        paginationSettings={paginationSettings}
        handleChangeOrder={handleChangeOrder}
      />
      <div className={`modal-overlay ${isOpenModal ? 'modal-open' : 'hidden'}`}>
        <div className='modal'>
          <button className='close-button' onClick={handleCloseModal}>
            <img
              src={`${process.env.PUBLIC_URL}/resources/contest/icon-close.svg`}
              alt='Exito'
              loading='lazy'
            />
          </button>
          <div className='modal-content'>
            <div>
              <img
                src={messageModal.success ? `${process.env.PUBLIC_URL}/resources/contest/icon-check.svg`: `${process.env.PUBLIC_URL}/resources/contest/icon-alert.svg`}
                alt='Exito'
                loading='lazy'
                className='mx-auto mb-4'
              />
            </div>
            <div>
              <h3 className="text-center" dangerouslySetInnerHTML={{__html: messageModal.message}}></h3>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
