import React from 'react';
import Carousel from 'react-multi-carousel';
import { GetBodyInnerHTML } from '../../modules/services/getElements.service';
import { useHistory } from 'react-router-dom';

const CustomDot = ({ onClick, className, ...rest }: any) => {
    const { active } = rest;
    return (
        <button
            className={`custom-dot ${active ? "active" : "inactive"} ${className}`}
            onClick={onClick}
        />
    );
};

const Slider = (props: any) => {
    const {
        children,
        className,
        dotColor = 'black',
        responsive = {
            mobile: {
                breakpoint: { max: 5000, min: 0 },
                items: 1,
                slidesToSlide: 1
            }
        },
        ...rest
    } = props

    return (
        <Carousel
            className={className}
            customDot={<CustomDot className={dotColor} />}
            showDots
            swipeable
            responsive={responsive}
            {...rest}
        >
            {children}
        </Carousel>
    )
};

export const SliderItem = (props: any) => {
    const { slider, onClick } = props;
    const history = useHistory()

    const openUrl = (data: any) => {
        if (!data?.includes('https://')) {
            history.push(data)

        }
        else window.open(data)
    }
    return (
        <div className="people-content">
            <div className="people-img">
                {slider.content?.type === 'video' && (
                    <div className="people-video">
                        <video
                            autoPlay
                            src={slider?.content?.content}
                            style={{ width: '100%', height: '15rem', backgroundColor: '#000000' }}
                            loop
                            controls={false}
                            muted
                        />
                    </div>
                )}
                {slider.content?.type === 'infografia' && (
                    <img
                        alt={slider.title}
                        src={slider.content?.content}
                    />
                )}
            </div>
            <div className="people-desc">
                <div className="people-title">
                    <span className="text-small white">{slider.title}</span>
                    <span className="text-big black">{slider.subtitle}</span>
                </div>
                <div className="people-content">
                    <GetBodyInnerHTML content={slider.description} />
                    <button className='btn-black' onClick={() => openUrl(slider.link.uri)}>{slider.link.value}</button>
                </div>
            </div>
        </div>
    )
}

export default Slider;
