import React from 'react';
import { capitalizeFirstLetter } from '../../modules/services/getElements.service';
import { useHistory } from 'react-router-dom';

const Header = (props: any) => {
    const history = useHistory()
    const { data } = props

    const goToHome = () => history.push('/')

    return (
        <div className="people-banner">
            <div className="banner-left">
                <div className="breadcrumb">
                    <span className='white'><span className='home' onClick={goToHome}>Home</span>&nbsp;{'>'}&nbsp;</span>
                    <span className='yellow'>{capitalizeFirstLetter(data?.title)}&nbsp;{capitalizeFirstLetter(data?.subtitle)}</span>
                </div>
                <div className="banner-title">
                    <p className="text-small white">{data?.title}</p>
                    <p className="text-big yellow">{data?.subtitle}</p>
                </div>
            </div>
            <div className="banner-right">
                <img
                    alt={data?.title}
                    src={data?.image}
                />
            </div>
        </div>
    )
};

export default Header;
