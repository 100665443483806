import { useGetAppsHubApiQuery } from '../../api/userSession';
import { apiMainURL } from '../../config/environment';
import { product } from '../../models/product.model';
import { shoppingOrder } from '../../models/shoppingOrder.model';
import DOMpurify from 'dompurify';
import reactParser from 'html-react-parser';

interface Image {
  src: string
  type: string
}

interface Banner {
  altText: string
  src: Image
  caption: string
  link: string
}

interface News {
  id: string | number
  title: string
  desc: string
  link: any
}

export const getGenders = (genderOptions: any) => {
  let genders: any[] = []
  genderOptions?.data.map((gender: any, i: number) => {
    genders[i] = {
      label: gender?.attributes?.name || '',
      value: gender?.attributes?.drupal_internal__tid?.toString() || '',
    }
  })

  return genders
}

export const getProfileData = (profileData: any) => {
  const profile = profileData?.data?.length > 0 ? profileData.data[0].attributes : {}
  let gender = ''

  profileData?.included?.map((inc: any) => {
    if (inc?.type === 'taxonomy_term--genero') {
      gender = inc?.attributes?.drupal_internal__tid?.toString()
    }
  })

  return {
    phone: profile.field_telefono,
    gender,
  }
}

export const getHeaders = (section: any) => {
  var elements: any[] = [];
  section?.data.map((result: any, i: number) => { })

  return elements
}

export function bannersInSection(section: any): any[] {
  var elements: any[] = [];
  let contador = 0;
  let title = ''; let subtitle = ''; let description = ''; let order = '99999';
  let link = {};
  section?.data.map((result: any, index: number) => {
    let image = {};
    let band = false;
    // let slides = getSlider(result || '', section?.included);
    // console.log("SLIDER ARMADO:  ",slides)
    // if (band) {
    //   elements[contador] = {
    //     altText: result?.attributes?.title || '',
    //     title: result?.attributes?.field_primary_title || '',
    //     subtitle: result?.attributes?.field_secondary_title || '',
    //     src: image || { src: '', type: '' },
    //     caption: result?.attributes?.field_description?.value || '',
    //     link: getURL(result?.attributes?.field_link?.uri || ''),
    //     order: result?.attributes?.field_order || '9999999'
    //   };
    //   contador++;
    // }
    title = result?.attributes?.field_primary_title || '';
    subtitle = result?.attributes?.field_secondary_title || '';
    description = result?.attributes?.field_description?.value || '';
    order = result?.attributes?.field_order || '9999999';
    link = {
      value: result?.attributes?.field_link?.title || '',
      uri: getURL(result?.attributes?.field_link?.uri || ''),
    }
    const content = getSliderMultimedia(result?.relationships?.field_contenido_multimedia?.data, section?.included);
    elements.push({
      title,
      subtitle,
      content,
      description,
      link,
      order
    });

  });

  return elements;
}
export function getBannerCompetition(
  key: any,
  elem: any,
  includes: any,
): string {
  let banner: string = '';
  let mediaImage = elem[key]?.data?.id || '';
  let idmedia = '';
  includes?.map((include: any, i: number) => {
    if (include.type == 'media--image' && include.id == mediaImage) {
      idmedia = include?.relationships?.field_media_image?.data.id || '';
      includes?.map((inc: any, index1: number) => {
        if (idmedia == inc.id) {
          banner = apiMainURL + inc.attributes.uri.url;
        }
      });
    }
  });
  return banner;
}
export function getListPrizes(idP: string, included: any[]): object {
  let prizes: object[] = [];
  let titleSection = '';
  let descSection = '';
  included.forEach((inc: any, i: number) => {
    if (inc?.type == 'paragraph--contest_prizes' && idP == inc?.id) {
      titleSection = inc?.attributes?.field_title || '';
      descSection = inc?.attributes?.field_description?.value || '';
      inc?.relationships?.field_prize_list?.data?.map(
        (el: any, index: number) => {
          prizes.push(getPrize(el?.id, included));
        },
      );
    }
  });
  return {
    title: titleSection || '',
    desc: descSection || '',
    content: prizes,
  };
}
function getPrize(idP: string, included: any[]): object {
  let prize = {};
  included.map((inc: any, index: number) => {
    if (inc?.type == 'paragraph--prize_list' && inc?.id == idP) {
      prize = {
        name: inc?.attributes?.field_name || '',
        src: getImage(inc?.relationships?.field_imagen?.data?.id, included),
      };
      return prize;
    }
  });
  return prize;
}
export function getStart(idS: string, included: any[]): object {
  let element: object = {};
  included.map((inc: any, index: number) => {
    if (inc?.type == 'paragraph--contest_preview' && inc?.id == idS) {
      element = {
        name: inc?.attributes?.field_title || '',
        description: inc?.attributes?.field_description?.value || '',
        src: getImage(inc?.relationships?.field_image?.data?.id, included),
      };
    }
  });
  return element;
}
export function getSection(idS: string, included: any[]) {
  let section = {};
  let idmedia = '';
  let banner = '';
  included.map((inc: any, index: number) => {
    if (inc?.type == 'paragraph--contest_section' && inc?.id == idS) {
      if (inc?.relationships?.field_banner?.data?.id) {
        banner = getBannerParagraph(
          inc?.relationships?.field_banner?.data?.id,
          included,
        );
      }
      section = {
        name: inc?.attributes?.field_title || '',
        description: inc?.attributes?.field_description?.value || '',
        src: banner || '',
      };
      return section;
    }
  });
  return section;
}
export function getBannerParagraph(id: any, included: any[]): string {
  let idMedia = '';
  let banner = '';
  included?.map((include: any, index: number) => {
    if (include.id == id) {
      idMedia = include?.relationships?.field_media_image?.data?.id || '';
      included?.map((inc: any) => {
        if (inc?.id == idMedia) {
          banner = apiMainURL + inc?.attributes?.uri?.url || '';
        }
      });
    }
  });
  return banner;
}
export function getBannerDefaultSection(
  elements: any[],
  included: any[],
): object[] {
  let banners: object[] = [];
  let image2 = {};
  elements?.forEach((element) => {
    let band = false;
    included?.map((inc: any, index: number) => {
      if (element?.id == inc.id) {
        if (inc?.relationships?.field_image?.data?.id) {
          band = true;

          let id = inc?.relationships?.field_image?.data?.id || '';
          included?.map((include: any) => {
            if (id === include.id) {
              image2 = {
                src: getImage(
                  inc?.relationships?.field_image?.data?.id,
                  included,
                ),
                type: 'image',
              };
            }
          });
        } else {
          if (inc?.relationships?.field_video?.data?.id) {
            band = true;

            let id = inc?.relationships?.field_video?.data?.id || '';
            image2 = {
              src: getBannerVideo(id, included),
              type: 'video',
            };
          }
        }
        banners.push({
          altText: '',
          caption: '',
          link: getURL(inc?.attributes?.field_link?.uri || '') || '',
          src: image2,
        });
      }
    });
  });
  return banners;
}
export function getListLinks(elements: any[], included: any[]): object[] {
  let links: object[] = [];
  elements?.forEach((element) => {
    included?.map((inc: any, index: number) => {
      if (element?.id == inc.id) {
        let image = getImage(
          inc?.relationships?.field_image?.data?.id,
          included,
        );
        links.push({
          title: inc?.attributes?.title || '',
          column: inc?.attributes.field_column || '',
          description: inc?.attributes?.body?.value || '',
          image: image || '',
          link: getURL(inc?.attributes?.field_link?.uri || ''),
        });
      }
    });
  });
  return links;
}
export function getMultimediaDefault(
  elements: any[],
  included: any[],
): object[] {
  let media: object[] = [];
  elements?.forEach((element) => {
    included?.map((inc: any, index: number) => {
      if (element?.id == inc.id) {
        let objElem = {};
        switch (inc?.type) {
          case 'node--video':
            let video = getVideoMultimedia(inc?.id, included);
            objElem = {
              type: 'video',
              src: video,
              title: inc?.attributes?.title || ''
            };
            break;
          case 'node--infografia':
            let info = getInfographic(inc?.id, included);
            objElem = {
              type: 'infografia',
              src: info,
            };
            break;
        }
        media.push(objElem);
      }
    });
  });
  return media;
}
export function getInclude(id: string, included: any[]): any {
  included?.map((inc: any, index: number) => {
    if (inc?.id == id) {
      return inc;
    }
  });
  return undefined;
}
export function getTermsAndConditions(idT: string, included: any[]): object {
  let terms = {};
  included.map((inc: any, index: number) => {
    if (inc?.type == 'paragraph--terms_conditions' && inc?.id == idT) {
      terms = {
        name: inc?.attributes?.field_title || '',
        description: inc?.attributes?.field_detail?.value || '',
      };
      return terms;
    }
  });
  return terms;
}
export function bannersInSection2Form(section: any): Element[] {
  var elements: any[] = [];
  let idNodeBanner: string;
  section?.data.map((result: any, i: number) => {
    result?.relationships?.field_banners?.data?.map((el: any, in3: number) => {
      let bannerPillar =
        getBannerWellnessPillarComplete(el.id, section.included) || '';
      elements.push({
        altText: '',
        src: bannerPillar,
        caption: '',
        link: '',
      });
    });
  });
  return elements;
}
export function bannersInSectionEcommerce(section: any): Element[] {
  var elements: any[] = [];
  section?.data.map((result: any, i: number) => {
    let id = result?.relationships?.field_image?.data?.id;
    let image = '/resources/img-product.jpg';
    elements[i] = {
      altText: result.attributes.title || '',
      src: {
        type: 'image',
        src: image || '',
      },
      caption: result.attributes.body?.value || '',
      link: getURL(result.attributes.field_link?.uri || ''),
    };
  });
  return elements;
}
export function GetApps() {
  return useGetAppsHubApiQuery('').data;
}
export function getNews(n: any): any[] {
  let news: any[] = [];
  let category = '';
  n?.data?.forEach((element: any) => {
    let idImage = element?.relationships?.field_imagen_destacada?.data?.id;
    let image = getImage(idImage, n.included);
    category = element?.attributes?.field_categoria[0] || '';
    news.push({
      image: image || '',
      title: element?.attributes?.title || '',
      description: element?.attributes?.body?.value || '',
      date: toLocaleDate(element?.attributes?.created) || '',
      id: element?.id || '',
    });
  });
  return news;
}
export function getRelevantNews(section: any): any[] {
  let news: any[] = [];
  section?.forEach((element: any) => {
    news.push({
      image: apiMainURL + element.field_imagen_destacada || '',
      title: element.title || '',
      description: element.body || '',
      date: element.created || '',
      id: element.uuid || '',
    });
  });
  return news;
}
export function appsInSection(section: any): any[] {
  var elements: any[] = [];
  section?.map((result: any, i: number) => {
    elements[i] = {
      author: result?.name || '',
      name: result?.name || '',
      src: result?.icon || '',
      text_info: result?.description,
      logo_pais: '/resources/home/simplifica_maz.svg',
      text_dis: ':',
      /* text_dis: 'Disponible en:', */
      href: getURL(result?.access_url || ''),
      tag: result?.group || '',
      width: '20',
    };
  });
  return elements;
}
export function getSeparator(id: string, included: any[]): string {
  let band = false;
  let s = '';
  included?.map((inc: any, index: number) => {
    if (inc?.id == id) {
      band = true;
      s = inc?.attributes?.field_px;
      return s;
    }
  });
  return band ? s : '';
}
// let tarjetas:Object;
// tarjetas=
// {
//   title: tar?.data?.attributes?.title || '',
//   image: getImage(
//     tar?.data?.relationships?.field_imagen_destacada?.data?.id,
//     tar?.included,
//   ),
//   id: tar?.data?.id || '',
// }
export async function getCards(id: string, included: any[]): Promise<any> {
  var myHeaders: Headers = new Headers();
  myHeaders.append(
    'authorization',
    `Bearer ${localStorage.getItem('localToken')?.toString() || ''}`,
  );
  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  return await fetch(
    `${apiMainURL}/jsonapi/node/tarjeta/${id}?include=field_imagen_destacada, field_banners, field_banners.field_image`,
    requestOptions,
  );
}
export function getDefaultContent(section: any): any {

  var element: any;
  section?.data?.map((elem: any, index: number) => {
    let idContent = elem?.relationships?.field_arguments?.data?.id || '';
    let banners: object[] = [];
    let listLinks: object[] = [];
    let sections: object[] = [];
    let media: object[] = [];
    let idmedio: any;
    let breadCrumb: object[] = [];
    let infografia: string;
    section?.included?.map((inc: any, index: number) => {
      switch (inc?.type) {
        case 'taxonomy_term--sections':
          breadCrumb.unshift({
            name: inc?.attributes?.name || '',
            path: inc?.attributes?.path?.alias || '',
          });
          break;
        case 'node--header':
          let header = getHeader(inc || '', section?.included);
          sections.push({
            type: 'header',
            content: header
          });
          break;
        case 'node--tarjeta':
          const sectionCard = getSectionCard(inc || '', section?.included);
          sections.push({
            type: 'sectionCard',
            content: sectionCard
          });
          break;
        case 'taxonomy_term--content_category':
          sections.push({
            type: 'relatedContent',
            content: inc?.attributes?.drupal_internal__tid || ''
          });
          break;
      }
      if (inc?.id == idContent) {
        inc?.relationships?.field_contenido.data.map(
          async (content: any, index: number) => {
            section?.included?.map((i: any) => {
              if (content?.id == i.id) {
                idmedio = i;
              }
            });
            if (idmedio)
              switch (idmedio?.type) {
                case 'paragraph--paragraph_banners':
                  banners = getBannerDefaultSection(
                    idmedio?.relationships?.field_list?.data,
                    section?.included,
                  );
                  sections.push({
                    type: 'banner',
                    content: banners,
                  });
                  break;
                case 'paragraph--titulos':
                  sections.push({
                    type: 'title',
                    content: {
                      type: idmedio?.attributes?.field_tipo_titulo || '',
                      value: idmedio?.attributes?.field_title || '',
                    },
                  });
                  break;
                case 'paragraph--paragraph_texto':
                  sections.push({
                    type: 'text',
                    content: {
                      sumary: idmedio?.attributes?.field_texto?.summary || '',
                      value: idmedio?.attributes?.field_texto?.value || '',
                    },
                  });
                  break;
                case 'paragraph--paragraph_enlaces':
                  listLinks = getListLinks(
                    idmedio?.relationships?.field_list?.data,
                    section?.included,
                  );
                  sections.push({
                    type: 'links',
                    content: listLinks,
                  });
                  break;
                case 'paragraph--infografia':
                  infografia = getInfographic
                    (
                      idmedio?.relationships?.field_infografia?.data?.id,
                      section?.included,
                    );
                  sections.push({
                    type: 'infografia',
                    content: infografia,
                  });
                  break;
                case 'paragraph--paragraph_multimedia':
                  media = getMultimediaDefault(
                    idmedio?.relationships?.field_list?.data,
                    section?.included,
                  );
                  sections.push({
                    type: 'media',
                    content: media,
                  });
                  break;
                case 'paragraph--separador':
                  let sep = getSeparator(idmedio?.id, section?.included);
                  sections.push({
                    type: 'separator',
                    content: {
                      px: sep || '0',
                    },
                  });
                  break;

                case 'paragraph--paragraph_tarjeta':
                  // getcards(idmedio?.relationships?.field_list?.data, section?.included)
                  let cardGroup: any[] = [];
                  cardGroup.push({
                    type: 'card',
                    id: idmedio?.relationships?.field_list?.data?.id || ''
                  })
                  sections.push({
                    type: 'cardGroup',
                    content: cardGroup
                  });

                  break;
                case 'paragraph--pdf':
                  let pdf = getPDF(idmedio?.relationships?.field_pdf?.data?.id || '', section?.included);
                  sections.push({
                    type: 'pdf',
                    content: pdf
                  });
                  break;
                case 'paragraph--slides':
                  let slides = getSlider(idmedio?.relationships?.field_slide?.data || '', section?.included);
                  sections.push({
                    type: 'slider',
                    content: slides
                  });
                  break;
                case 'paragraph--item_ficha':
                  let image = getMediaProductEcommerce(idmedio?.relationships?.field_icon_image?.data?.id || '', section?.included);
                  const ficha = {
                    image,
                    description: idmedio?.attributes?.field_item_description,
                    link: idmedio?.attributes?.field_link,
                  }
                  sections.push({
                    type: 'ficha',
                    content: ficha
                  });
                  break;

              }
          }
        );
      }
    });

    element = {
      breadcrumb: breadCrumb,
      title: elem?.attributes?.title || '',
      content: sections,
    };
  });
  return element;
}

export function getBasicDefaultContent(section: any): any {
  var element: any;
  let sections: object[] = [];
  section?.data?.map((elem: any, index: number) => {
    let header: any = {}
    section?.included?.map((inc: any, index: number) => {
      if (inc?.type == 'node--header' && inc?.id == elem?.relationships?.field_header?.data?.id) {
        header = getHeader(inc || '', section?.included);
      }
    });
    element = {
      title: (header?.title && header?.subtitle) ? (header?.title + ' ' + header?.subtitle) : elem?.attributes?.title || '',
      link: elem?.attributes?.field_url || '',
      content: header?.description ? header?.description : '',
      image: header?.image
    };
    sections.push(element);
  });
  return sections;
}

export function contentInSection(section: any): Element[] {
  var elements: any[] = [];
  section?.data.map((result: any, i: number) => {
    let id = result.relationships.field_image.data.id;
    let image = '';
    section?.included?.map((include: any) => {
      if (id === include.id) {
        image = apiMainURL + include.attributes.uri.url;
      }
    });

    elements[i] = {
      title: result.attributes.title,
      image: image,
      description: result.attributes.body?.value
        ? result.attributes.body.value
        : '',
      link: getURL(result.attributes.field_link?.uri || ''),
      column: result.attributes.field_column,
      order: result.attributes.field_order || 999,
    };
  });
  return elements.sort(function (a, b) {
    return a.order - b.order;
  });
}

export function getURL(urlIn: string): string {
  let url_fake: string[] = urlIn.split(':');
  let url: string = '';
  url_fake[0] == 'internal' ? (url = url_fake[1]) : (url = urlIn);
  return url;
}

export function getPhone(id: string, included: any[]): string {
  let phone: string = '';
  included?.map((include: any) => {
    if (include.type === 'paragraph--item_ficha' && id === include.id) {
      phone = include?.attributes?.field_item_description;
    }
  });
  return phone;
}

export interface Media {
  type: string;
  src: string;
}

export function getBanner(id: string, included: any[]): string {
  let banner: string = '';
  let idNodeBanner = '';
  included?.map((include: any) => {
    if (include.type === 'node--slider' && id == include.id) {
      idNodeBanner = include?.relationships?.field_image?.data?.id;
    }
    if (include.type === 'node--ficha' && id == include.id) {
      idNodeBanner = include?.relationships?.field_imagen_miniatura?.data?.id;
    }
  });
  included?.map((include: any) => {
    if (include.type === 'file--file' && include.id == idNodeBanner) {
      banner = apiMainURL + include?.attributes?.uri?.url;
    }
  });
  return banner;
}
export function getSliderMultimedia(node: any, included: any[]): any {
  let banner: string = '';
  let idNodeBanner: any;
  let media = {}
  if (node?.type == 'node--infografia') {
    media = {
      type: 'infografia',
      content: getInfographic(node.id, included)
    }
  }
  if (node?.type == 'node--video') {
    media = {
      type: 'video',
      content: getVideo(node.id, included)
    }
  }
  return media;
}

export const getSlider = (data: any[], included: any[]): any[] => {
  let sliders: any[] = [];
  let title = '';
  let subtitle = '';
  let description = '';
  let link = {}
  data?.map((itemSlide: any) => {
    included?.map((include: any) => {
      if (include.type == 'node--slider' && itemSlide?.id == include.id) {
        title = include?.attributes?.field_primary_title || '';
        subtitle = include?.attributes?.field_secondary_title || '';
        description = include?.attributes?.field_description?.value || '';
        link = {
          value: include?.attributes?.field_link?.title || '',
          uri: getURL(include?.attributes?.field_link?.uri || ''),
        }
        const content = getSliderMultimedia(include?.relationships.field_contenido_multimedia.data, included);
        sliders.push({
          title,
          subtitle,
          content,
          description,
          link,
        })
      }

    });


  })
  return sliders;
}

export const getHeader = (data: any, included: any[]): any => {
  let header: any = {};
  let title = data?.attributes?.field_primary_title || '';
  let subtitle = data?.attributes?.field_secondary_title || '';
  let description = data?.attributes?.field_description?.value || '';
  const image = getImage(data?.relationships?.field_image?.data?.id, included);
  header = {
    title,
    subtitle,
    image,
    description
  }
  return header;
}

export const getItems = (data: any[], included: any[]): any[] => {
  let items: any[] = [];
  let title = '';
  let description = '';
  let descriptionLeader = '';
  let email = '';
  let phone = '';
  let link = {}
  data?.map((item: any) => {
    included?.map((include: any) => {
      if (include.type === 'node--ficha' && item?.id == include.id) {
        title = include?.attributes?.title || '';
        descriptionLeader = include?.attributes?.field_leader_description || '';

        description = include?.attributes?.body?.processed || '';
        link = {
          value: include?.attributes?.field_link?.title || '',
          uri:include?.attributes?.field_link?.uri || '#',
        };
        phone = getPhone(include?.relationships?.field_item?.data[1]?.id, included)
        email = getPhone(include?.relationships?.field_item?.data[0]?.id, included)
      }
    });
    const image = getBanner(item?.id, included);
    items.push({
      title,
      image,
      description,
      link,
      email,
      phone,
      descriptionLeader
    })
  })
  return items;
}

export const getHomeSection = (section: any) => {
  const result = getSectionCard(section?.data[0], section?.included)
  return result
}

export const getSectionCard = (data: any, included: any[]): any => {
  let sectionCard: any = {};
  let title = data?.attributes?.field_primary_title || '';
  let subtitle = data?.attributes?.field_secondary_title || '';
  let description = data?.attributes?.field_description?.value || '';
  let style = data?.attributes?.field_style || '';
  let hasButton = !!data?.attributes?.field_limit;
  let hasModal = !!data?.attributes?.field_modal_detail;
  const items = getItems(data?.relationships?.field_ficha?.data, included)
  sectionCard = {
    title,
    subtitle,
    description,
    style,
    items,
    hasButton,
    hasModal
  }
  return sectionCard;
}

export function getText(id: string, included: any[]): string {
  let banner: string = '';
  let idNodeBanner = '';
  included?.map((include: any) => {
    if (include.type == 'node--content_banner' && id == include.id) {
      idNodeBanner = include?.relationships?.field_image?.data?.id;
    }
  });
  included?.map((include: any) => {
    if (include.type == 'file--file' && include.id == idNodeBanner) {
      banner = apiMainURL + include?.attributes?.uri?.url;
    }
  });
  return banner;
}

export function getPDF(id: string, included: any[]): string {
  let band = false;
  let url = '';
  included?.map((inc: any, index: number) => {
    if (inc?.id == id) {
      included?.map((included2: any) => {
        if (included2?.id == inc?.relationships?.field_pdf?.data?.id) {
          url = apiMainURL + included2?.attributes?.uri?.url || ''

        }
      })
    }
  });
  return url;
}

export function getAllMultimedia(media: any, included: any): any[] {
  let dataMediaTabs: any[] = [];
  media?.data?.forEach((cont: any) => {
    if (cont.type == 'node--infografia') {
      let info = getInfographic(cont.id, included);
      dataMediaTabs.push({ type: 'infografia', src: info });
    }
    if (cont.type == 'node--video') {
      let info = getVideo(cont.id, included);
      dataMediaTabs.push({ type: 'video', src: info });
    }
  });
  return dataMediaTabs;
}
export function getMultimedia(section: any): any {
  let files: any[] = [];
  let dataMedia: Media[] = [];
  section?.map((element: any) => {
    if (element.type == 'file--file') {
      files.push(element);
    }
  });
  section?.map((element: any) => {
    if (element.type == 'node--video' || element.type == 'node--infografia') {
      element.type == 'node--video'
        ? dataMedia.push({
          type: 'video',
          src:
            apiMainURL +
            getDataMedia(
              getIdMedia(
                element?.relationships?.field_video?.data?.id,
                section,
              ),
              files,
            ),
        })
        : dataMedia.push({
          type: 'infografia',
          src:
            apiMainURL +
            getDataMedia(
              element?.relationships?.field_image?.data?.id,
              files,
            ),
        });
    }
  });
  return dataMedia;
}
function getIdMedia(id: string, files: any[]): string {
  let idVideo = '';
  files?.forEach((file: any) => {
    if (file.type == 'media--video' && id == file.id) {
      idVideo = file.relationships?.field_media_video_file?.data?.id + '';
      return idVideo;
    }
  });
  return idVideo;
}
function getDataMedia(id: any, files: any[]): string {
  let src = '';
  files?.forEach((file: any) => {
    if (id == file.id) {
      src = file.attributes.uri.url + '';
    }
  });
  return src;
}
export function getVideo(id: string, includes: any[]): string {
  let idNode = '';
  let idMedia = '';
  let video = '';
  includes?.forEach((include: any) => {
    if (include.type == 'node--video' && include.id == id) {
      idNode = include?.relationships?.field_video?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'media--video' && include.id == idNode) {
      idMedia = include?.relationships?.field_media_video_file?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == idMedia) {
      video = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + video;
}
export function getVideoMultimedia(id: string, includes: any[]): object {
  let idNode = '';
  let idImage = '';
  let idMedia = '';
  let video = '';
  let image: string | null = null;
  includes?.forEach((include: any) => {
    if (include.type == 'node--video' && include.id == id) {
      idNode = include?.relationships?.field_video?.data?.id || '';
      idImage = include?.relationships?.field_image?.data?.id || null;
    }
  });
  if (idImage) {
    includes?.forEach((include: any) => {
      if (include.type == 'file--file' && include.id == idImage) {
        image = include?.attributes?.uri?.url;
      }
    });
  }
  includes?.forEach((include: any) => {
    if (include.type == 'media--video' && include.id == idNode) {
      idMedia = include?.relationships?.field_media_video_file?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == idMedia) {
      video = include?.attributes?.uri?.url || '';
    }
  });

  return {
    src: apiMainURL + video,
    preview: image
  };
}
export function getBannerVideo(id: string, includes: any[]): string {
  let idMedia = '';
  let video = '';
  includes?.forEach((include: any) => {
    if (include.type == 'media--video' && include.id == id) {
      idMedia = include?.relationships?.field_media_video_file?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == idMedia) {
      video = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + video;
}
export function getInfographic(id: string, includes: any[]): string {
  let idNode = '';
  let infographic = '';
  includes?.forEach((include: any) => {
    if (include.type == 'node--infografia' && include.id == id) {
      idNode = include?.relationships?.field_image?.data?.id || '';
    }
  });
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == idNode) {
      infographic = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + infographic;
}
export function getImage(id: string, includes: any[]): string {
  let image = '';
  includes?.forEach((include: any) => {
    if (include.type == 'file--file' && include.id == id) {
      image = include?.attributes?.uri?.url || '';
    }
  });

  return apiMainURL + image;
}

export function getCategoryOrTagsProductEcommerce(
  id: string,
  includes: any[],
): string {
  let tag = '';
  includes?.forEach((include: any) => {
    if (include.id == id) {
      tag = include?.attributes?.name || '';
    }
  });
  return tag;
}

export function getMediaProductEcommerce(id: string, includes: any[]): string {
  let media = '';
  let preMedia = '';
  includes?.forEach((include: any) => {
    if (include.id == id) {
      if (include.type == 'media--image')
        preMedia = include?.relationships?.field_media_image?.data?.id;
      else {
        if (include.type == 'media--video') {
          preMedia = include?.relationships?.field_media_video_file?.data?.id;
        }
      }
    }
  });
  includes?.forEach((include: any) => {
    if (include.id == preMedia) {
      media = include?.attributes?.uri?.url || '';
    }
  });
  return apiMainURL + media;
}
export function getVideoProductEcommerce(id: string, includes: any[]): string {
  let image = '';
  let preImage = '';
  includes?.forEach((include: any) => {
    if (include.id == id) {
      preImage = include?.relationships?.field_media_video_file?.data?.id;
    }
  });
  includes?.forEach((include: any) => {
    if (include.id == preImage) {
      image = include?.attributes?.uri?.url || '';
    }
  });
  return apiMainURL + image;
}
export function getProducts(data: any): product[] {
  let productos: product[] = [];
  data?.data?.map((elem: any) => {
    let sections: any[] = [];
    let tags: string[] = [];
    let rel: object[] = elem?.relationships?.field_gallery?.data;
    let tagData: object[] = elem?.relationships?.field_tags?.data;
    let fCategory = getCategoryOrTagsProductEcommerce(
      elem?.relationships?.field_product_category?.data?.id || '',
      data?.included,
    );
    let type = '';
    let src = '';
    let category = '';
    rel.forEach((r: any) => {
      switch (r?.type) {
        case 'media--image':
          src = getMediaProductEcommerce(r?.id, data?.included);
          type = 'image';

          break;
        case 'media--video':
          src = getMediaProductEcommerce(r?.id, data?.included);
          type = 'video';
          break;
        case 'taxonomy_term--tags':
          let t = getCategoryOrTagsProductEcommerce(r?.id, data?.included);
          tags.push(t);
          type = 'tag;';
          break;
        default:
          break;
      }
      if (type == 'video' || type == 'image') {
        sections.push({
          type: type,
          content: {
            src: src,
          },
        });
      }
    });
    tagData.forEach((t: any) => {
      if (t?.type == 'taxonomy_term--tags') {
        tags.push(getCategoryOrTagsProductEcommerce(t?.id, data?.included));
      }
    });
    productos.push({
      name: elem?.attributes?.title || '',
      gallery: [...sections],
      description: elem?.attributes?.field_description?.value || '',
      tags: [...tags],
      points: parseInt(elem?.attributes?.field_points_gamification || '0'),
      volume: elem?.attributes?.field_presentation || '',
      price: elem?.attributes?.field_price || '',
      type: fCategory,
      calification: 5,
      internID: elem?.attributes?.drupal_internal__nid || '',
    });
  });

  return productos;
}
export function getProductsShoppingCart(data: any): any[] {
  let productos: any[] = [];
  data?.map((elem: any) => {
    let sections: any[] = [];
    elem?.media?.forEach((m: any) => {
      let src = '';
      let type = '';

      if (m?.type?.includes('image')) {
        src = m?.url || '';
        type = 'image';
      } else {
        if (m?.type?.includes('video')) {
          src = m?.url || '';
          type = 'video';
        }
      }
      sections.push({
        type: type,
        content: {
          src: src,
        },
      });
    });
    productos.push({
      name: elem?.product_name || '',
      gallery: [...sections],
      description: '',
      volume: elem?.product_presentation || '',
      price: elem?.price || '',
      internID: elem?.product_id || '',
      quantity: elem?.quantity || '',
      total_price: elem?.total_price || '',
      ecommerce_orders_id: elem?.id || '',
    });
  });

  return productos;
}
export function getResumenPedidoActual(data: any): shoppingOrder {
  let total = getTotalSummary(getProductsShoppingCart(data?.items));
  let orden: shoppingOrder;
  orden = { ...data, total: total };
  return orden;
}
export function getTotalSummary(products: any[]) {
  let total = 0;
  products.forEach((p) => {
    total += parseFloat(p?.price) * parseFloat(p?.quantity);
  });
  return total;
}
export function getBannerWellnessPillarComplete(
  id: string,
  includes: any[],
): any {
  let image = '';
  let mediaVideo = '';
  let fileVideo = '';
  let banner = {};
  includes?.forEach((include: any) => {
    if (include.id == id) {
      if (include?.relationships?.field_image?.data) {
        let idImage = include?.relationships?.field_image?.data?.id || '';
        image = getImage(idImage, includes);
        banner = {
          type: 'image',
          src: image,
        };
      } else {
        if (include?.relationships?.field_video?.data) {
          let idVideo = include?.relationships?.field_video?.data?.id || '';
          includes.map((i2: any) => {
            if (i2.id == idVideo) {
              let idMVF =
                i2?.relationships?.field_media_video_file?.data?.id || '';
              includes.map((i3: any) => {
                if (i3?.id == idMVF) {
                  fileVideo = i3?.attributes?.uri?.url || '';

                  banner = {
                    type: 'video',
                    src: apiMainURL + fileVideo,
                  };
                }
              });
            }
          });
        }
      }
      return banner;
    }
  });

  return banner;
}
export const linksSection: any = {
  bienestar_pilares: '/wellness/DetailPilar',
  bienestar_programas: '/programs-events/details-programs',
  diversidad_articulos: '/diversity/details',
  referencia_marca: '/trademarks/details',
  news_feed: '/detail-news',
  ficha: '/pillars/details',
  bienestar_eventos: '/programs-events/details-events',
  tarjeta: '/pillars/details',
};
export function toLocaleDate(dateIn: string): string {
  let fechaMs = Date.parse(dateIn);
  let date = new Date(fechaMs);
  date.setDate(date.getDate() + 1);
  return date?.toLocaleDateString(['es-ES'], {
    //Typescript ways of adding the type
    month: 'long',
    day: 'numeric',
  });
}
enum SocialResponse {
  'https://www.facebook.com/' = 'bi bi-facebook',
  'https://facebook.com' = 'bi bi-facebook',
  'facebook.com/' = 'bi bi-facebook',
  //Twitter
  'https://www.twitter.com/' = 'bi bi-twitter',
  'https://twitter.com' = 'bi bi-twitter',
  'twitter.com/' = 'bi bi-twitter',
  //Instagram
  'https://www.instagram.com/' = 'bi bi-instagram',
  'https://instagram.com' = 'bi bi-instagram',
  'instagram.com/' = 'bi bi-instagram',
  //network
  'facebook' = 'bi bi-facebook',
  'twitter' = 'bi bi-twitter',
  'instagram' = 'bi bi-instagram',
}
export function translateNetwork(network: string): string {
  if (network.includes('facebook')) {
    return SocialResponse.facebook;
  }
  if (network.includes('twitter')) {
    return SocialResponse.twitter;
  }
  if (network.includes('instagram')) {
    return SocialResponse.instagram;
  }
  return '';
}

export function GetBodyInnerHTML(props: any) {
  const cleanHtmlString = DOMpurify.sanitize(props.content, {
    USE_PROFILES: { html: true },
  });
  const reactElement = reactParser(cleanHtmlString);

  return <>{reactElement}</>;
}
export function capitalizeFirstLetter(string: string) {
  return string?.toLowerCase()?.charAt(0)?.toUpperCase() + string?.toLowerCase()?.slice(1);
}

export const getBasicNewsFeed = (section: any): News[] => {
  let elements: News[] = []
  section?.data.map((news: any, i: number) => {
    const extern = /^(ftp|http|https):\/\/[^ "]+$/.test(news?.attributes?.field_link?.uri);
    elements[i] = {
      title: news?.attributes?.title || '',
      desc: news?.attributes?.body?.value || '',
      id: news?.attributes?.drupal_internal__nid,
      link: { uri: getURL(news?.attributes?.field_link?.uri), extern },
    }
  })

  return elements
}