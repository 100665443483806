import React, { useEffect } from 'react';
import { GetBodyInnerHTML } from '../../modules/services/getElements.service';
import { useHistory } from 'react-router-dom';

const RelatedArticle = (props: any) => {
    const { data } = props;
    const history = useHistory()

    const openUrl = (data: any) => {
     
      history.push(data)
    }
    useEffect(() => {
        
    }, [props]);
    return (
        <div className="related-article">
            <div className="related-article-image-container">
               {data?.image &&(
                 <img
                 alt={data?.title}
                 src={data?.image}
             />
               )}
            </div>
            <p className="related-title">{data?.title}&nbsp;{data?.subtitle}</p>
            <span className="related-description">
                <GetBodyInnerHTML content={data?.content} />
            </span>
            <button className='related-link' onClick={()=>openUrl(data?.link)}>
                Leer artículo{' '}
                <img
                    alt='Leer artículo'
                    src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-related-article.svg`}
                />
            </button>
        </div>
    )
};

export default RelatedArticle;
