import React from 'react';

interface CultureCardProps {
    item?: any;
    index?: number;
    activeCards?: any[];
    language?: string;
    handleClick: (index: number) => void;
    images?: any;
    icons?: any;
    fullScreen?: boolean;
    children?: React.ReactNode;
}

function Card({
                         item = {},
                         index = 0,
                         activeCards = [],
                         language = "esp",
                         handleClick,
                         images = {},
                         icons = {},
                         fullScreen = false,
                         children = null
                     }: CultureCardProps) {

    const [isFlipped, setIsFlipped] = React.useState(false);
    if (fullScreen) {
        activeCards = [index];
    }

    React.useEffect(()=>{
        if(!fullScreen){
            if(activeCards.includes(index)){
                setIsFlipped(true);
            } else {
                setIsFlipped(false);
            }
        }else {
            setIsFlipped(false);
        }
    },[activeCards])

    const handleClickFlip = () => {
        if(!fullScreen){
            setIsFlipped(!isFlipped);
        }

    };

    const getCardType = (categoryId:any) => {
        switch (categoryId) {
            case '1':
                return 'card-black';
            case '2':
                return 'card-yellow';
            case '3':
                return 'card-white';
            default:
                return '';
        }
    };

    return (
        <div
            onClick={()=>{handleClickFlip();}}
            //onTransitionEnd={()=>{setIsFlipped(false)}}
            className={`cc ${fullScreen ? "full-screen":""} ${isFlipped ? 'flipped' : activeCards.includes(index) ?'flipped': ''}`}>
            <div
                key={item?.id}
                onClick={() => {handleClick(index);}}
                className={`culture-card ${activeCards.includes(index)?"active":""} ${getCardType(item.category.id)} ${fullScreen && "full-screen"}`}
                style={{
                    cursor: "pointer",
                    backgroundSize: !fullScreen ? activeCards.includes(index) ? '64px' : 'cover': 'unset',
                    backgroundPosition: !fullScreen ? (activeCards.includes(index) ? '95% 5%' : 'center'): 'unset',
                    backgroundImage: (item.category.id === '2' && activeCards.includes(index))? 'none' : (!fullScreen ? (activeCards.includes(index) ? (item.image.detail[language].length > 0 ? `url(${item.image.detail[language][0]})` : 'none') : (item.image.cover[language].length > 0 ? `url(${item.image.cover[language][0]})` : 'none')) : 'none')
                }}
            >
                <div className={`culture-card-container position-relative ${activeCards.includes(index) ? "active":""}`}>
                    {(item.category.id === '1' || item.category.id === '3') && (
                        activeCards.includes(index) ? (
                            <>
                                <img src={images.logoAbi} className="position-absolute culture-card-container-logo-left" alt="" />
                            </>
                        ) : (
                            <div className="culture-card-container-tag position-absolute">
                                {item.category.text[language]}
                            </div>
                        )
                    )}

                    <div className={`d-flex flex-column ${item.category.id === '2' && activeCards.includes(index) && !fullScreen ? "justify-content-start":"justify-content-center"} culture-card-container-data ${fullScreen && "full-screen"}`}>
                        {activeCards.includes(index) && item.category.id === '1' && (
                            <>
                                { fullScreen && <img src={item.image.detail[language][0]} className = "culture-card-container-logo-right" alt="" />}
                                {item.title && <div className={`culture-card-container-data-title ${fullScreen && "full-screen"}`}>{item.title[language]}</div>}
                                {item.subtitle.first && <div className={`culture-card-container-data-subtitle-first ${fullScreen && "full-screen"}`}>{item.subtitle.first[language]}</div>}
                                {item.subtitle.second && <div className={`culture-card-container-data-subtitle-second ${fullScreen && "full-screen"}`}>{item.subtitle.second[language]}</div>}
                            </>
                        )}

                        {item.category.id === '3' && (
                            <>
                                {activeCards.includes(index) && item.content ? (
                                    <div className={`culture-card-container-data-content ${fullScreen && "full-screen"}`} dangerouslySetInnerHTML={{ __html: item.content[language] }} />
                                ) : (
                                    <div className="culture-card-container-data-name">
                                        {item.title[language]}
                                    </div>
                                )}
                            </>
                        )}

                        {item.category.id === '2' && (
                            <>
                                {activeCards.includes(index) && item.content && ([
                                    !fullScreen ? <div className={`culture-card-container-data-title-group ${fullScreen && "full-screen"}`}>
                                        {item.subtitle.first && <div className={`culture-card-container-data-title-small ${fullScreen && "full-screen"}`}>{item.subtitle.first[language]}</div>}
                                        {item.title && <div className={`culture-card-container-data-title-big ${fullScreen && "full-screen"}`}>{item.title[language]}</div>}
                                    </div>: <div className="spacer-info"/>,
                                        <div
                                            className={`culture-card-container-data-question ${fullScreen ? "full-screen" : ""} ${language}`}
                                            dangerouslySetInnerHTML={{__html: item.content[language]}}/>
                                    ])
                                }
                            </>
                        )}
                    </div>

                    {activeCards.includes(index) ? (
                        item.category.id === '1' ? (
                            <img src={icons.flipBlack} className="a position-absolute img-flip-black" alt="" />
                        ) : (
                            <img src={icons.flipWhite} className={`position-absolute img-flip-white ${fullScreen && "full-screen"}`} alt="" />
                        )
                    ) : (
                        item.category.id === '1' ? (
                            <>
                                <img src={images.duenosWhite && images.duenosWhite[language]} className="position-absolute img-duenos" alt="" />
                                <img src={icons.flipWhite} className={`position-absolute img-flip-white ${fullScreen && "full-screen"}`} alt="" />
                            </>
                        ) : (
                            <>
                                <img src={images.duenosBlack && images.duenosBlack[language]} className="position-absolute img-duenos" alt="" />
                                <img src={icons.flipBlack} className="position-absolute img-flip-black" alt="" />
                            </>
                        )
                    )}


                    {children}
                </div>

            </div>

        </div>

    );
}

export default Card;
