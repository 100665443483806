import { Link } from 'react-router-dom';

// Components
import Pagination from '../../../Pagination';

interface ListvideoProps {
  handleChangeOrder: (elem: any) => void;
  handlePagination: (nro: any) => void;
  paginationSettings: {
    current: number;
    totalPages: number;
    total: number;
    from: number;
    to: number;
  };
  listvideo: {
    id: string;
    video: string;
    title: string;
  }[];
}

export default function ListVideo(props: ListvideoProps) {
  const { handleChangeOrder, handlePagination, paginationSettings, listvideo } =
    props;
  return (
    <section>
      <div className='video_contest--filter'>
        <div className='form-select-container'>
          <label htmlFor='sortBy'>Order por:</label>
          <select
            name='sortBy'
            id='sortBy'
            className='form-select'
            onChange={handleChangeOrder}
          >
            <option value='popular'>Popular</option>
            <option value='oldest'>Más antiguos</option>
            <option value='latest'>Más recientes</option>
          </select>
        </div>
        <div>
          <p>
            {paginationSettings.from} - {paginationSettings.to} de{' '}
            {paginationSettings.total} Videos
          </p>
        </div>
      </div>
      <div className='video_contest--videos'>
        {listvideo /*  */ &&
          listvideo.length > 0 &&
          listvideo.map((video, index) => (
            <div className='video_contest--play' key={`list-video-${index}`}>
              <Link to={`/embajador-mision/${video.id}`}>
                <video src={video.video} width='300px' height='230px'></video>
                <img
                  src={`${process.env.PUBLIC_URL}/resources/contest/icon-play.svg`}
                  alt='Play'
                  title='Play'
                  className='btn-play'
                />
                <span></span>
                <p>{video.title}</p>
              </Link>
            </div>
          ))}
      </div>
      {listvideo && listvideo.length === 0 && (
        <p className='text-center text-secondary'>Sin videos</p>
      )}
      <div className='video_contest--pagination'>
        <Pagination
          onPageChange={handlePagination}
          pageCount={paginationSettings.totalPages}
        />
      </div>
    </section>
  );
}
